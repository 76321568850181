import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectIsAuth } from '../../Redux/LoginSlice'
import axios from '../../axios'
import Loader from '../Loader'
import style from './BuyItem.module.sass'

function Article() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [trashOn, setTrashOn] = useState(false)

	const isAuth = useSelector(selectIsAuth)
	useEffect(() => {
		if (isAuth) {
			const { email } = isAuth
			if (email === 'tehnoshark2284@gmail.com') {
				setTrashOn(true)
			}
		}
	}, [isAuth])

	useEffect(() => {
		setLoading(false)
		axios
			.get('/article')
			.then(res => {
				setData(res.data)
				setLoading(true)
			})
			.catch(err => {
				console.log(err)
				alert('Ошибка сервера')
			})
	}, [])

	const Trash = async item => {
		await axios.delete(`/article/${item}`)
	}
	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}

	return (
		<>
			<Helmet>
				<title>Статьи о недвижимости и агентствах</title>
				<meta
					name='description'
					content='Узнайте все о работе агентств недвижимости: как выбрать лучшее агентство, как получить лучшую цену за свою недвижимость, какие услуги предоставляют агентства, какие риски связаны с продажей или покупкой недвижимости через агентства. Читайте наши статьи, оставляйте свои комментарии и вопросы.'
				/>
			</Helmet>
			<div className={style.articleList}>
				<h2>Аналитика, обзоры рынка недвижимости</h2>
				<div className={style.articleListWrap}>
					{data
						.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
						.map(article => (
							<div className={style.cartWrap} key={article._id}>
								{trashOn ? (
									<button
										onClick={() => Trash(article._id)}
										className={style.cartTrash}
									>
										<FontAwesomeIcon icon={faTrash} />
									</button>
								) : null}
								{loading ? (
									<Link
										className={`${style.link} ${'link-text-decor-none'}`}
										to={`/article-full/${article._id}`}
									>
										<div className={style.imgtWrap}>
											<img
												src={`https://evbulsun.site:9000/uploads/${article.image}`}
												alt='phpoto'
											/>
										</div>
										<div className={style.textWrap}>
											<h2>{article.title}</h2>
										</div>
									</Link>
								) : (
									<Loader />
								)}
								<p className={style.articleTime}>
									{new Date(article.createdAt).toLocaleString('tr-TR', options)}
								</p>
							</div>
						))}
				</div>
			</div>
		</>
	)
}

export default Article
