import React, { useEffect, useRef, useState } from 'react';
import axios from '../../axios';
import Loader from '../Loader';
import './GptChat.sass';

const GPT3Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const messagesEndRef = useRef(null);
    const [loading, setLoading] = useState(false);


    const sendMessage = async (e) => {
        e.preventDefault();

        if (input.trim() === '') return;

        const userMessage = { text: input, sender: 'user' };
        setInput('');

        try {
            setLoading(true);
            const response = await axios.post('/api/gpt3', { input: input });
            const botMessage = response.data.output;
            setMessages([...messages, userMessage, { text: botMessage, sender: 'bot' }]);
        } catch (error) {
            console.error('Error while sending message to GPT-3 API:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);


    return (
        <div className="chatbot">
            {loading ? <Loader /> : null}
            <div className="messages">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`} ref={index === messages.length - 1 ? messagesEndRef : null}>
                        {message.text}
                    </div>
                ))}
            </div>
            <form className='userText-form' onSubmit={sendMessage}>
                <input
                    type="text"
                    className='userText'
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Введите ваше сообщение..."
                />
                <button type="submit">Отправить</button>
            </form>
        </div>
    );
};

export default GPT3Chatbot;
