import React, { useEffect, useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Link, useParams } from 'react-router-dom'
import axios from '../../axios'
import PopupCards from '../PopupCards'
import '../RentalOwner/seller.sass'
import ImageSlider from '../SliderPreview'

function SellerDataPageItem() {
	const { id } = useParams()
	const [data, setData] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [isToastVisible, setToastVisible] = useState(false)

	useEffect(() => {
		axios
			.get(`/to-advertise-item/${id}`)
			.then(res => {
				setData(res.data)
			})
			.catch(err => {
				console.log(err)
				alert('Ошибка сервера')
			})
	}, [id])

	const photo = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}
	const options = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
		timeZone: 'Europe/Istanbul',
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}

	const handleCopy = async () => {
		try {
			const textToCopy = `https://evbulsun.site/seller-datapage-item/${id}`
			await navigator.clipboard.writeText(textToCopy)
			setToastVisible(true) // Показать тост
			setTimeout(() => setToastVisible(false), 2000) // Автоматически скрыть через 3 секунды
		} catch (err) {
			console.error('Не удалось скопировать текст: ', err)
		}
	}

	return (
		<div className='last-requests seller-owner-item'>
			<Link to='/data-page'>
				<button className='homeBeginBtn homeBeginBtnDataPage'>
					смотреть все
				</button>
			</Link>

			<h2>
				{data.city} / {data.district} / Комнат - {data.rooms} / Цена:{' '}
				{data.price} {''}
				{data.currency}
			</h2>

			<div className='last-requests-wrap'>
				<div key={data._id} className='lastRequestCardsWrap'>
					<ul className='lastRequestCard'>
						{data.verified ? (
							<div className='cardVerifiedWrapper cardVerifiedWrapperSellerDataPageItem'>
								<span
									className='cardVerified'
									title='Этот знак означает что администрация сайта лично посетила и проверила объект и рекомендует к рассмотрению.'
								>
									Проверено
								</span>
								<p className='cardVerifiedText'>
									Этот знак означает, что администрация сайта лично осмотрела и
									проверила объект, и рекомендует его к рассмотрению.
								</p>
							</div>
						) : null}
						<li className='imgLi'>
							{data.images ? (
								data.images.map((item, index) => (
									<img
										key={index}
										onClick={() => photo()}
										src={`https://evbulsun.site:9000/uploads/${item}`}
										alt='Фото товара'
									/>
								))
							) : (
								<img
									src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
									alt='Фото товара'
								/>
							)}
						</li>

						<PopupCards isOpen={isOpen} onClose={handleClose}>
							{data.images && <ImageSlider images={data.images} />}
							{!data.images && (
								<img
									src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
									alt='Фото товара'
								/>
							)}
						</PopupCards>

						<li className='CardLi'>
							<p>Дата:</p>
							<p className='date'>
								{new Date(data.createdAt).toLocaleString('tr-TR', options)}
							</p>
						</li>

						<li className='CardLi'>
							<p>Посуточно или короткий срок:</p>
							<p>{data.howlong}</p>
						</li>
						<li className='CardLi'>
							<p>Город:</p>
							<p>{data.city}</p>
						</li>
						<li className='CardLi'>
							<p>Количество комнат:</p>
							<p>{data.rooms}</p>
						</li>

						<li className='CardLi'>
							<p>Тип:</p>
							<p>{data.type}</p>
						</li>
						<li className='CardLi'>
							<p>Район:</p>
							<p>{data.district}</p>
						</li>
						<li className='description'>
							<p>Дополнительная информация:</p>
							<ReactMarkdown>{data.additionalInfo}</ReactMarkdown>
						</li>
						<li className='CardLi'>
							<p>Тип недвижимости:</p>
							<p>{data.propertyType}</p>
						</li>

						<li className='CardLi'>
							<p>Тип кухни:</p>
							<p>{data.kitchenType}</p>
						</li>
						<li className='CardLi'>
							<p>Общее количество этажей:</p>
							<p>{data.totalFloors}</p>
						</li>
						<li className='CardLi'>
							<p>Текущий этаж:</p>
							<p>{data.currentFloor}</p>
						</li>
						<li className='CardLi'>
							<p>Мебель:</p>
							<p>{data.furniture}</p>
						</li>
						<li className='CardLi'>
							<p>Цена:</p>
							<p>{data.price}</p>
						</li>
						<li className='CardLi'>
							<p>Валюта:</p>
							<p>{data.currency}</p>
						</li>
						<li className='CardLi'>
							<p>Адрес:</p>
							<p>
								<a
									href={data.address}
									target='_blank'
									rel='noopener noreferrer'
								>
									{data.address}
								</a>
							</p>
						</li>
						<li className='CardLi'>
							<p>Площадь:</p>
							<p>{data.area}</p>
						</li>
						<li className='CardLi'>
							<p>Телефон:</p>
							<a href={`tel:${data.phone}`}>{data.phone}</a>
						</li>
						<li className='CardLi'>
							<p>Ссылка:</p>
							{!isToastVisible && (
								<a
									href={`https://evbulsun.site:9000/seller-datapage-item/${data._id}`}
									onClick={e => {
										e.preventDefault()
										handleCopy()
									}}
								>
									https://evbulsun.site/seller-datapage-item/${data._id}
								</a>
							)}
							{isToastVisible && (
								<div className='toast'>
									<h4>Ссылка скопирована в буфер обмена!</h4>
								</div>
							)}
						</li>
						<li className='CardLi'>
							<p>Просмотры</p>
							<p>{data.viewsCount}</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default SellerDataPageItem
