import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.sass';
import logo from '../../img/Ev.jpg';


function Footer() {


    return (
        <footer>
            <div className={styles.footerWrapp}>
                <div className={styles.logo}>
                    <img src={logo} alt="logo" />
                </div>
                <div className={styles.contactInfo}>
                    <p >email@: evbulsun@gmail.com</p>
                    {/* <p>Phone: 555-555-5555</p> */}
                </div>
                <div className={styles.feedbackLink}>
                    <Link className='link-text-decor-none' to='/politic'><div className={styles.Link}> Политика конфиденциальности</div></Link>
                </div>
            </div >
        </footer >
    )
}

export default Footer;
