import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../Redux/LoginSlice.js'
import axios from '../../axios.js'
import ItemCard from '../ItemCard/index.jsx'
import style from './DataPage.module.sass'
import './FilterPopupStyle.sass'

const ITEMS_PER_PAGE = 10

function DataPage() {
	const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false)
	const [trashOn, setTrashOn] = useState(false)
	const [dateUpdate, setDataUpdate] = useState(false)
	const [selectedCardIndex, setSelectedCardIndex] = useState(null)
	const [searchQuery, setSearchQuery] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [filters, setFilters] = useState({
		city: '',
		rooms: '',
		type: '',
		propertyType: '',
		kitchenType: '',
		furniture: '',
	})

	const fetchData = async () => {
		try {
			const response = await axios.get('/to-advertise')
			setData(response.data)
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}

	const searchFilter = (searchQuery, item) => {
		return Object.values(item).some(value => {
			return (
				(typeof value === 'string' || value instanceof String) &&
				value.toLowerCase().includes(searchQuery.toLowerCase().trim())
			)
		})
	}

	const applyFilters = useMemo(() => {
		let filteredData = data

		Object.keys(filters).forEach(key => {
			if (filters[key]) {
				filteredData = filteredData.filter(item => item[key] === filters[key])
			}
		})

		filteredData = filteredData.filter(item => searchFilter(searchQuery, item))
		return filteredData
	}, [data, filters, searchQuery])

	const handleFilterChange = (filterName, filterValue) => {
		setFilters({
			...filters,
			[filterName]: filterValue,
		})
	}

	useEffect(() => {
		fetchData()
	}, [])

	const handleFilterSubmit = e => {
		e.preventDefault()
		setFilters({
			city: '',
			rooms: '',
			type: '',
			propertyType: '',
			kitchenType: '',
			furniture: '',
		})
	}

	const isAuth = useSelector(selectIsAuth)
	useEffect(() => {
		if (isAuth) {
			const { email } = isAuth
			if (email === 'tehnoshark2284@gmail.com') {
				setTrashOn(true)
				setDataUpdate(true)
			}
		}
	}, [isAuth])

	const handleClose = () => {
		setIsOpen(false)
	}

	const photo = cardIndex => {
		setSelectedCardIndex(cardIndex)
		setIsOpen(true)
	}

	const Trash = async item => {
		await axios.delete(`/to-advertise-delete/${item}`)
	}

	const DateUpdateBtn = async item => {
		await axios.get(`/to-advertise-update/${item}`)
	}

	const verifiedBtn = async item => {
		await axios.get(`/to-advertise-verified/${item}`)
	}

	const options = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
		timeZone: 'Europe/Istanbul',
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}

	const paginatedData = applyFilters
		.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
		.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)

	const totalPages = Math.ceil(applyFilters.length / ITEMS_PER_PAGE)

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1)
		}
	}

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1)
		}
	}
	const openFilterPopup = () => {
		setIsFilterPopupOpen(true)
	}
	const closeFilterPopup = () => {
		setIsFilterPopupOpen(false)
	}

	return (
		<div>
			{isFilterPopupOpen && (
				<div className='dataPagePopupFilterStyle'>
					<div className='dataPagePopupFilterStyle-inner'>
						<button
							className='btn openFilterPopupBtn closeFilterPopupBtn'
							onClick={closeFilterPopup}
						>
							Закрыть
						</button>
						<div className={style.dataPageFilterStyle}>
							<div className={style.dataPageFilterStyle}>
								<form onSubmit={handleFilterSubmit}>
									<label>
										Город:
										<select
											value={filters.city}
											onChange={e => handleFilterChange('city', e.target.value)}
										>
											<option value=''>Выберите...</option>
											<option value='Alanya'>Алания</option>
											<option value='Ankara'>Анкара</option>
											<option value='Antalya'>Анталия</option>
											<option value='Bursa'>Бурса</option>
											<option value='Demre'>Демре</option>
											<option value='Eskişehir'>Эскишехир</option>
											<option value='İstanbul'>Стамбул</option>
											<option value='İzmir'>Измир</option>
											<option value='Kaş'>Каш</option>
											<option value='Kemer'>Кемер</option>
											<option value='Konya'>Конья</option>
											<option value='Manavgat'>Манавгат</option>
											<option value='Mersin'>Мерсин</option>
											<option value='Serik'>Серик</option>
										</select>
									</label>

									<label>
										Количество комнат:
										<select
											value={filters.rooms}
											onChange={e =>
												handleFilterChange('rooms', e.target.value)
											}
										>
											<option value=''>Выберите...</option>
											{Array.from({ length: 10 }, (_, i) => i + 1).map(num => (
												<option key={num} value={num}>
													{num}
												</option>
											))}
										</select>
									</label>

									<label>
										Тип жилья:
										<select
											value={filters.propertyType}
											onChange={e =>
												handleFilterChange('propertyType', e.target.value)
											}
										>
											<option value=''>Выберите...</option>
											<option value='Новостройка'>Новостройка</option>
											<option value='Вторичное жилье'>Вторичное жилье</option>
										</select>
									</label>

									<label>
										Тип квартиры:
										<select
											value={filters.type}
											onChange={e => handleFilterChange('type', e.target.value)}
										>
											<option value=''>Выберите...</option>
											<option value='Квартира (апартаменты)'>
												Квартира (апартаменты)
											</option>
											<option value='Квартира (дуплекс)'>
												Квартира (дуплекс)
											</option>
											<option value='Дом (вилла)'>Дом (вилла)</option>
											<option value='Таунхаус'>Таунхаус</option>
											<option value='Ком. недвижимость'>
												Ком. недвижимость
											</option>
											<option value='Земельный участок'>
												Земельный участок
											</option>
										</select>
									</label>

									<label className={style.dataPageRadio}>
										Тип кухни:
										<div>
											<label>
												<input
													type='radio'
													value='Студия'
													checked={filters.kitchenType === 'Студия'}
													onChange={e =>
														handleFilterChange('kitchenType', e.target.value)
													}
												/>
												<p>Студия</p>
											</label>
											<label>
												<input
													type='radio'
													value='Отдельная кухня'
													checked={filters.kitchenType === 'Отдельная кухня'}
													onChange={e =>
														handleFilterChange('kitchenType', e.target.value)
													}
												/>
												<p>Отдельная кухня</p>
											</label>
										</div>
									</label>

									<label className={style.dataPageRadio}>
										Наличие мебели:
										<div>
											<label>
												<input
													type='radio'
													value='с мебелью'
													checked={filters.furniture === 'с мебелью'}
													onChange={e =>
														handleFilterChange('furniture', e.target.value)
													}
												/>
												С мебелью
											</label>
											<label>
												<input
													type='radio'
													value='без мебели'
													checked={filters.furniture === 'без мебели'}
													onChange={e =>
														handleFilterChange('furniture', e.target.value)
													}
												/>
												Без мебели
											</label>
										</div>
									</label>

									<button type='submit'>Очистить фильтры</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}

			<button className='btn openFilterPopupBtn' onClick={openFilterPopup}>
				Фильтры
			</button>

			{/* Здесь вы можете отображать данные */}
			<div className='last-requests'>
				<input
					type='text'
					placeholder='Search'
					onChange={e => setSearchQuery(e.target.value.toString())}
				/>
				<h2>Тут все объявления, в верху самые новые.</h2>
				<p>Воспользуйтесь поиском что бы найти по цене или описанию.</p>
				<p>Найдено {applyFilters.length} объявлений.</p>
				<div className='pagination'>
					<button disabled={currentPage === 1} onClick={handlePrevPage}>
						Предыдущая
					</button>
					<span>
						{currentPage} из {totalPages}
					</span>
					<button
						disabled={currentPage === totalPages}
						onClick={handleNextPage}
					>
						Следующая
					</button>
				</div>
				<div className='last-requests-wrap'>
					{paginatedData.map((item, cardIndex) => (
						<ItemCard
							key={item._id}
							item={item}
							cardIndex={cardIndex}
							trashOn={trashOn}
							Trash={Trash}
							photo={photo}
							isOpen={isOpen}
							handleClose={handleClose}
							selectedCardIndex={selectedCardIndex}
							filteredData={applyFilters}
							options={options}
							DateUpdateBtn={DateUpdateBtn}
							dateUpdate={dateUpdate}
							verifiedBtn={verifiedBtn}
							paginatedData={paginatedData}
						/>
					))}
				</div>
				<div className='pagination'>
					<button disabled={currentPage === 1} onClick={handlePrevPage}>
						Предыдущая
					</button>
					<span>
						{currentPage} из {totalPages}
					</span>
					<button
						disabled={currentPage === totalPages}
						onClick={handleNextPage}
					>
						Следующая
					</button>
				</div>
			</div>
		</div>
	)
}

export default DataPage
