import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../axios'
import PopupCards from '../PopupCards'
import ImageSlider from '../SliderPreview'
import './seller-owner-item.sass'

function SellerOwnerItem() {
	const { id } = useParams()
	const [data, setData] = useState([])
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		axios
			.get(`/buyitem/${id}`)
			.then(res => {
				setData(res.data)
			})
			.catch(err => {
				console.log(err)
				alert('Ошибка сервера')
			})
	}, [id])

	const photo = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}
	const options = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
		timeZone: 'Europe/Istanbul',
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}

	return (
		<div className='last-requests seller-owner-item'>
			<h2>Предложение по покупке.</h2>

			<div className='last-requests-wrap'>
				<div key={data._id} className='lastRequestCardsWrap'>
					<ul className='lastRequestCard'>
						<li className='imgLi'>
							{data.images ? (
								data.images.map((item, index) => (
									<img
										key={index}
										onClick={() => photo()}
										src={`https://evbulsun.site:9000/uploads/${item}`}
										alt='Фото товара'
									/>
								))
							) : (
								<img
									src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
									alt='Фото товара'
								/>
							)}
						</li>

						<PopupCards isOpen={isOpen} onClose={handleClose}>
							{data.images && <ImageSlider images={data.images} />}
							{!data.images && (
								<img
									src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
									alt='Фото товара'
								/>
							)}
						</PopupCards>

						<li className='CardLi'>
							<p>Date:</p>
							<p className='date'>
								{new Date(data.createdAt).toLocaleString('tr-TR', options)}
							</p>
						</li>

						<li className='CardLi'>
							<p>Link:</p>
							<p>
								<a href={data.link}>Ссылка на объявление в других источниках</a>
							</p>
						</li>
						<li className='CardLi'>
							<p>Price:</p>
							<p>{data.price}</p>
						</li>

						<li className='description'>
							<p>Description:</p>
							<p>{data.description}</p>
						</li>
						<li className='CardLi'>
							<p>Phone:</p>
							<p>{data.phoneNumber}</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default SellerOwnerItem
