import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout, selectIsAuth } from '../../Redux/LoginSlice'
import logo from '../../img/Ev.jpg'
import GoogleTranslate from '../GoogleTranslate'
import GPT3Chatbot from '../GptChat'
import Popup from '../Popup/homePopup'
import TradingViewWidget from '../TradingViewWidget'
import './VnjChatBot.sass'
import './burger.css'
import style from './header.module.sass'

function Header() {
	const [burgerOpen, setBurgerOpen] = useState(false)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [adminMenu, setAdminMenu] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const isAuth = useSelector(selectIsAuth)
	const dispatch = useDispatch()

	// const [login, setLogin] = useState(false);

	const burger = () => {
		setBurgerOpen(!burgerOpen)
	}

	useEffect(() => {
		if (isAuth) {
			const { name, email } = isAuth
			setName(name)
			setEmail(email)
		}
	}, [isAuth])
	const logoutFN = () => {
		dispatch(logout())
		window.localStorage.removeItem('token')
	}
	useEffect(() => {
		if (email === 'tehnoshark2284@gmail.com') {
			setAdminMenu(true)
		}
	}, [email])

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<div>
			<div
				className={`${style.Header} ${burgerOpen ? style.headerBurger : null}`}
			>
				<Link className='link-text-decor-none' to='/'>
					<div className={style.logo}>
						<img src={logo} alt='logo' />
					</div>
				</Link>
				<ul>
					<Link className='link-text-decor-none' to='/'>
						<li onClick={() => setBurgerOpen(false)}>Home</li>
					</Link>
					<Link className='link-text-decor-none' to='/about'>
						<li onClick={() => setBurgerOpen(false)}>О проекте</li>
					</Link>
					<Link className='link-text-decor-none' to='/all-reviews'>
						<li onClick={() => setBurgerOpen(false)}>Отзывы</li>
					</Link>

					<Link className='link-text-decor-none' to='/article'>
						<li onClick={() => setBurgerOpen(false)}>Аналитика</li>
					</Link>
					<Link className='link-text-decor-none' to='/request'>
						<li onClick={() => setBurgerOpen(false)}>Купить</li>
					</Link>
					<Link className='link-text-decor-none' to='/rent-request'>
						<li onClick={() => setBurgerOpen(false)}>Аренда</li>
					</Link>
					<Link className='link-text-decor-none' to='/to-advertise'>
						<li onClick={() => setBurgerOpen(false)}>Продать</li>
					</Link>
					<Link className='link-text-decor-none' to='/to-advertise-rental'>
						<li onClick={() => setBurgerOpen(false)}>Сдать</li>
					</Link>
					{/* <Link className='link-text-decor-none' to='/token'><li onClick={() => setBurgerOpen(false)}>Airdrops</li></Link> */}
					{adminMenu ? (
						<div className={style.adminMenu}>
							<Link className='link-text-decor-none' to='/seller'>
								<li onClick={() => setBurgerOpen(false)}>Seller</li>
							</Link>
							<Link className='link-text-decor-none' to='/rental-owner '>
								<li onClick={() => setBurgerOpen(false)}>RentalOwner</li>
							</Link>
							<Link className='link-text-decor-none' to='/article-post '>
								<li onClick={() => setBurgerOpen(false)}>СоздатьСтатью</li>
							</Link>
						</div>
					) : null}
				</ul>
				<div className={style.headerBtnWrap}>
					{isAuth ? null : (
						<div>
							<Link className='link-text-decor-none' to='/login'>
								<button className={style.login}>Войти</button>
							</Link>
							<Link className='link-text-decor-none' to='/reg'>
								<button className={style.register}>Регистрация</button>
							</Link>
						</div>
					)}
					{isAuth ? (
						<div className={style.logoutWrap}>
							<div onClick={logoutFN} className={style.logOut}>
								Выйти
							</div>
							<Link className='link-text-decor-none' to='/cabinet'>
								<div className={style.userWrap}>
									<p>Личный кабинет</p>
									<span>{name}</span>
								</div>
							</Link>
						</div>
					) : null}
				</div>
				<div className={`${style.burgerWrap} ${'burgerWrap'}`}>
					<div
						id='nav-icon2'
						className={burgerOpen ? 'open' : null}
						onClick={burger}
					>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
				{/* <Marquee /> */}
			</div>
			<TradingViewWidget />

			<div onClick={() => setIsOpen(true)} className={style.chatBotBtn}>
				Нужна помощь? ChatBot
			</div>
			<Link className='link-text-decor-none vnjchatbot-link' to='/vnjchatbot'>
				<div>
					<p>Помощь в оформлении ВНЖ</p>
				</div>
			</Link>
			<GoogleTranslate />

			<Popup isOpen={isOpen} onClose={handleClose}>
				<h2>Задайте любой вопрос</h2>
				<p>
					Например, "Как пользоваться?" или "Помогите составить объявление".
				</p>
				<GPT3Chatbot />
			</Popup>
		</div>
	)
}

export default Header
