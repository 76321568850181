import React from 'react'
import style from '../PopupCards/PopupCards.module.sass'

function PopupCabinet({ isOpenCabinet, onCloseCabinet, children }) {
	return (
		<>
			{isOpenCabinet && (
				<div className={style.popup}>
					<div className={style.popupContent}>
						{children}
						<button className={style.closeBtn} onClick={onCloseCabinet}>
							Close
						</button>
					</div>
				</div>
			)}
		</>
	)
}

export default PopupCabinet
