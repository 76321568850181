import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from '../Popup/homePopup';
import '../CheckHouse/CheckHouse.sass';
import './SellApartment.sass';
import Helmet from 'react-helmet';

const SellApartment = () => {
    const [adLink, setAdLink] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await fetch(`https://api.telegram.org/bot5953316413:AAEcTStqojzvX7VZFD1eN0dmaAS9VdfAnjA/sendMessage?chat_id=-1001636610457&text= Продаю квартиру, объявление по ссылке  ${adLink} / моя почта ${email} / мой телефон ${phone}`)
                .then(res => res.json());
        } catch (error) {
            alert('Ошибка при отправке объявления!');
        } finally {
            setIsOpen(true);
        }
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const [openAccordion, setOpenAccordion] = useState(null);

    const handleAccordionClick = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };
    return (
        <div className='CheckHouse SellApartment'>
            <Helmet>
                <title>Оценка недвижимости и покупка квартир</title>
                <meta name="description" content="Получите профессиональную оценку стоимости вашей квартиры, разместите объявление о продаже и найдите лучшие предложения на рынке недвижимости с помощью нашего сервиса. Станьте нашим клиентом и продайте свою недвижимость быстрее и выгоднее." />
            </Helmet>
            <Popup isOpen={isOpen} onClose={handleClose}>
                <h2>Форма отправлена. 👍</h2>
                <h3>Проверяйте почту или телефон и успешных Вам сделок.</h3>

                <Link className='btn' to='/sell-apartment'><p onClick={() => setIsOpen(false)} >Назад</p></Link>

            </Popup>

            <div className='SellApartment-acc'>
                <ul className='CheckHouse-descr'>
                    <li className='CheckHouse-descr-li' key={0}>
                        <button onClick={() => handleAccordionClick(0)}>Как это работает?</button>

                        <ul className={`CheckHouse-descr-answer ${openAccordion === 0 ? 'CheckHouse-descr-answer-active' : ''}`}>
                            <li className='sell-apartment-title'>
                                Всего за несколько шагов получите результат с нашим сервисом:
                            </li>
                            <li>
                                1. Разместите объявление о продаже на любом сайте.
                            </li>
                            <li>
                                2. Зайдите на наш сайт и вставьте ссылку на свое объявление о продаже квартиры.
                            </li>
                            <li>
                                3. Нажмите "Отправить", и ваше объявление уйдет в наш Телеграм-канал.
                            </li>
                            <li>
                                4. Получите профессиональную оценку стоимости квартиры от наших экспертов и любого зантересованного в покупке человека.
                            </li>
                        </ul>


                    </li>
                    <li className='CheckHouse-descr-li' key={1}>
                        <button onClick={() => handleAccordionClick(1)}>Как мы оцениваем?</button>

                        <ul className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>
                            <li >
                                Наш подход к оценке основан на объективном анализе рынка и потребностей наших клиентов. Наша компания работает с инвесторами, профессионалами в сфере недвижимости и теми, кто занимается покупкой, ремонтом и последующей продажей жилья. Мы тщательно выбираем для наших клиентов лучшие объекты недвижимости, исходя из соотношения цены и качества.
                            </li>
                            <li >
                                Мы прекрасно знаем рынок недвижимости и предлагаем нашим клиентам только те объекты, которые соответствуют их требованиям и финансовым возможностям. Наша оценка недвижимости проводится по всем критериям, включая расположение, состояние, инфраструктуру и потенциал для роста стоимости.
                            </li>
                            <li >
                                В первую очередь мы оцениваем недвижимость с точки зрения прибыльности для наших клиентов. Мы стремимся к тому, чтобы каждая покупка недвижимости была выгодной, и наши клиенты могли рассчитывать на стабильный доход от своих инвестиций.
                            </li>
                        </ul>

                    </li>
                    <li className='CheckHouse-descr-li' key={2}>
                        <button onClick={() => handleAccordionClick(2)}>Кому это подходит?</button>

                        <ul className={`CheckHouse-descr-answer ${openAccordion === 2 ? 'CheckHouse-descr-answer-active' : ''}`}>
                            <li className='sell-apartment-title'>
                                Наша услуга идеально подойдет для людей, которые:
                            </li>
                            <li >
                                1. Продают недвижимость и хотят получить объективную оценку своей квартиры, дома или коммерческой недвижимости.
                            </li>
                            <li >
                                2. Ищут профессиональное мнение о стоимости своего жилья, чтобы понять, по какой цене оно может быть продано в кратчайшие сроки.
                            </li>
                            <li >
                                3. Хотят увеличить свои шансы на продажу недвижимости, привлекая дополнительное внимание к своему объявлению.
                            </li>
                            <li >
                                4. Стремятся сотрудничать с профессионалами в сфере недвижимости, которые могут помочь им найти заинтересованных покупателей и инвесторов.
                            </li>
                            <li >
                                5. Желают получить рекомендации по оптимальной цене продажи, учитывая текущую ситуацию на рынке недвижимости и индивидуальные особенности своего объекта.
                            </li>
                            <li className='sell-apartment-subtitle'>
                                Если вы относитесь к одной из этих категорий, то наш сервис оценки недвижимости будет полезен именно для вас.
                            </li>
                        </ul>

                    </li>
                    <li className='CheckHouse-descr-li' key={3}>
                        <button onClick={() => handleAccordionClick(3)}>Как стать нашим клиентом?</button>

                        <ul className={`CheckHouse-descr-answer ${openAccordion === 3 ? 'CheckHouse-descr-answer-active' : ''}`}>
                            <li >
                                Чтобы стать нашим клиентом, просто напишите нам на почту <a href="mailto:youremail@example.com">evbulsun@gmail.com</a> с указанием ваших контактных данных. Мы свяжемся с вами в кратчайшие сроки для обсуждения деталей и ответим на все ваши вопросы.

                            </li>
                        </ul>

                    </li>
                </ul>

            </div>


            <h1 className='SellApartment-title-h1'>Оценить квартиру и найти покупателей</h1>
            <form className=' SellApartment-form' onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="adLink">Ссылка на объявление:</label>
                    <input
                        type="text"
                        id="adLink-SellApartment"
                        name="adLink"
                        value={adLink}
                        onChange={(e) => setAdLink(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email">Ваша почта или Telegram:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label htmlFor="phone">Ваш телефон или WhatsApp:</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                </div>
                <button className='btn SellApartment-btn' type="submit" disabled={isOpen}>
                    Отправить
                </button>
            </form>
        </div>
    );
};

export default SellApartment;
