import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { fetchAuth, selectIsAuth } from '../../Redux/LoginSlice'
import style from './Login.module.sass'

function LoginForm() {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [formErrors, setFormErrors] = useState({})
	const [isSubmitting, setIsSubmitting] = useState(false)

	const handleEmailChange = event => {
		setEmail(event.target.value)
	}

	const handlePasswordChange = event => {
		setPassword(event.target.value)
	}

	const handleSubmit = event => {
		event.preventDefault()
		setFormErrors(validate())
		setIsSubmitting(true)
	}

	const validate = () => {
		let errors = {}

		if (!email.trim()) {
			errors.email = 'Email is required'
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = 'Email address is invalid'
		}

		if (!password) {
			errors.password = 'Password is required'
		} else if (password.length < 6) {
			errors.password = 'Password must be at least 6 characters'
		}

		return errors
	}

	const dispathc = useDispatch()
	const isAuth = useSelector(selectIsAuth)

	useEffect(() => {
		const submitForm = async () => {
			const formData = {
				email: email,
				password: password,
			}
			try {
				const data = await dispathc(fetchAuth(formData))
				if (!data.payload) {
					alert('Не удалось авторизоваться')
				}
				if ('token' in data.payload) {
					window.localStorage.setItem('token', data.payload.token)
				}
			} catch (error) {
				console.log(error)
			}
		}

		if (Object.keys(formErrors).length === 0 && isSubmitting) {
			submitForm()
		}
	}, [formErrors, isSubmitting, dispathc])

	if (isAuth) {
		return <Navigate to='/' />
	}

	return (
		<>
			<h1 className={style.registrationFormTitle}>
				Добро пожаловать обратно! Войдите в свой личный кабинет и продолжайте
				поиск жилья с удобством и безопасностью. Получайте новые предложения от
				хозяев недвижимости, отслеживайте свои запросы и сделки. Начните
				использовать наш удобный сервис уже сейчас.
			</h1>
			<div className={style.registrationForm}>
				<form onSubmit={handleSubmit}>
					<label>
						Email:
						<input type='email' value={email} onChange={handleEmailChange} />
						{formErrors.email && (
							<span className='error'>{formErrors.email}</span>
						)}
					</label>
					<label>
						Password:
						<input
							type='password'
							value={password}
							onChange={handlePasswordChange}
						/>
						{formErrors.password && (
							<span className='error'>{formErrors.password}</span>
						)}
					</label>

					<button className='btn' type='submit'>
						Login
					</button>
					<Link className='link' to='/resetpass'>
						<div className={style.resetPassword}>Забыли пароль?</div>
					</Link>
				</form>
			</div>
		</>
	)
}

export default LoginForm
