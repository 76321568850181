import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectIsAuth } from '../../Redux/LoginSlice'
import axios from '../../axios'
import style from '../BuyForm/BuyForm.module.sass'
import Popup from '../Popup/homePopup'
import WhatsAppButton from '../WhatsApp'
import './rentForm.sass'

function RentComponent() {
	const [roomCount, setRoomCount] = useState('')
	const [rentalPeriod, setRentalPeriod] = useState('')
	const [city, setCity] = useState('')
	const [isPawned, setIsPawned] = useState('')
	const [price, setPrice] = useState('')
	const [description, setDescription] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [activated, setActivated] = useState('')
	const formRef = useRef(null)

	const handleСityCountChange = e => {
		setCity(e.target.value)
	}

	const handleRoomCountChange = e => {
		setRoomCount(e.target.value)
	}
	const handleRentalPeriodChange = e => {
		setRentalPeriod(e.target.value)
	}

	const handlePawnedChange = e => {
		setIsPawned(e.target.value)
	}

	const handlePriceChange = e => {
		setPrice(e.target.value)
	}
	const [error, setError] = useState(false)
	const handleDescriptionChange = e => {
		setDescription(e.target.value)
		if (e.target.value.length > 300) {
			setError(true)
		} else {
			setError(false)
		}
	}

	const handleSubmit = async e => {
		e.preventDefault()
		const formData = {
			city: city,
			roomCount: roomCount,
			isPawned: isPawned,
			price: price,
			description: description,
			rentalPeriod: rentalPeriod,
		}

		try {
			const { data } = await axios.post('/rent', formData)
			console.log(data._id)
			// alert('Форма отправлена и ожидает оценки');
			formRef.current.reset()
			setIsOpen(true)

			fetch(
				`https://api.telegram.org/bot5953316413:AAEcTStqojzvX7VZFD1eN0dmaAS9VdfAnjA/sendMessage?chat_id=-1001945415016&text= Ищю квартиру в  ${data.city} / количество комнат  ${data.roomCount} /  срок  ${data.rentalPeriod} // ОСНОВНОЕ ОПИСАНИЕ  ${data.description} // ориентировочная цена  ${data.price} $ / Пожалуйста отвечайте по этой ссылке https://evbulsun.site/rent-item/${data._id}`
			)
				.then(res => res.json())
				.then(data => {
					console.log(data._id)
				})
		} catch (error) {
			console.warn(error)
			alert(
				'Oшибка заполнения формы!!! Что бы перезагрузить страницу нажмите OK'
			)
		}
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	const isAuth = useSelector(selectIsAuth)
	useEffect(() => {
		if (isAuth) {
			const { isActivated } = isAuth
			setActivated(isActivated)
		}
	}, [isAuth])

	return (
		<div className={`${style.BuyForm} ${'form'}`}>
			<div className='rentBtnLink'>
				<Link className='btn ' to='/data-page-rental'>
					<p className='rentBtnLinkText '>Смотреть объявления</p>
				</Link>
				<p>Тут все объявления от хозяев квартиры</p>
			</div>
			<h1 className='rentFormTitle'>
				Тут можно дать объявление о желании арендовать квартиру, хозяева сами
				ищут Вас.
			</h1>
			{activated ? null : (
				<div className='form-warning'>
					Перед заполнением формы ПОЖАЛУЙСТА зарегистрируйтесь и активируйте
					аккаунт.
					<p>ИЛИ</p>
					<WhatsAppButton
						phoneNumber='905444558407'
						message='Здравствуйте. Я ищу квартиру в аренду.'
					/>
				</div>
			)}
			<Popup isOpen={isOpen} onClose={handleClose}>
				<h2>Форма отправлена. 👍</h2>
				<h3>
					Предложения будут поступать на email который вы указали при
					регистрации.
				</h3>
				<h3>Проверяйте почту и успешных Вам сделок.</h3>

				<Link className='btn' to='/rent-form'>
					<p onClick={() => setIsOpen(false)}>Назад</p>
				</Link>
			</Popup>

			<form onSubmit={handleSubmit} ref={formRef}>
				<label htmlFor='city'>
					<p>Выберите город:</p>
					<select
						id='city'
						name='city'
						value={city}
						onChange={handleСityCountChange}
						required
					>
						<option value=''>Какой город?</option>
						<option value='Анталия'>Анталия</option>
						<option value='Алания'>Алания</option>
						<option value='Бодрум'>Бодрум</option>
						<option value='Измир'>Измир</option>
						<option value='Сиде'>Сиде</option>
						<option value='Мерсин'>Мерсин</option>
						<option value='Манавгат'>Манавгат</option>
					</select>
				</label>
				<label htmlFor='roomCount'>
					<p>Количество комнат:</p>
					<select
						id='roomCount'
						name='roomCount'
						value={roomCount}
						onChange={handleRoomCountChange}
						required
					>
						<option value=''>Сколько комнат?</option>
						<option value='0+1'>0+1</option>
						<option value='1+1'>1+1</option>
						<option value='2+1'>2+1</option>
						<option value='3+1'>3+1</option>
						<option value='4+1'>4+1</option>
					</select>
				</label>
				<label htmlFor='rentalPeriod'>
					<p>На какой период:</p>
					<select
						id='rentalPeriod'
						name='rentalPeriod'
						value={rentalPeriod}
						onChange={handleRentalPeriodChange}
						required
					>
						<option value=''>Срок?</option>
						<option value='на сутки'>на сутки</option>
						<option value='до 10 дней'>до 10 дней</option>
						<option value='до 1 месяца'>до 1 месяца</option>
						<option value='от 1 до 3 месяцев'>от 1 до 3 месяцев</option>
						<option value='до 6 месяцев'>до 6 месяцев</option>
						<option value='на год и больше'>на год и больше</option>
					</select>
				</label>
				<div>
					<span>Под внж:</span>
					<label htmlFor='pawnedYes'>
						<p>Да</p>
						<input
							type='radio'
							id='pawnedYes'
							name='isPawned'
							value='yes'
							checked={isPawned === 'yes'}
							onChange={handlePawnedChange}
							required
						/>
					</label>
					<label htmlFor='pawnedNo'>
						<p>Нет</p>
						<input
							type='radio'
							id='pawnedNo'
							name='isPawned'
							value='no'
							checked={isPawned === 'no'}
							onChange={handlePawnedChange}
							required
						/>
					</label>
				</div>
				<label htmlFor='price'>
					<p>Цена в 💲:</p>
					<input
						type='number'
						id='price'
						name='price'
						value={price}
						onChange={handlePriceChange}
						required
					/>
				</label>
				<label htmlFor='description'>
					<p>Текст объявления:</p>
					<textarea
						id='description'
						name='description'
						value={description}
						onChange={handleDescriptionChange}
						placeholder='1. Район
2. Бюджет
3. Количество человек
4. Срок
5. Дата заезда / когда готовы посмотреть
6. Животные есть / нет
7. Дальность к морю важна или нет
8. Дополнительные пожелания
9. Ограничение 300 символов'
						className={error ? `${style.error}` : ''}
						required
						maxLength={300}
					/>
					<p className={error ? `${style.block}` : `${style.none}`}>
						Привышен лимит в 300 символов!
					</p>
				</label>

				<button
					disabled={description.length > 300 || !activated}
					className='btn'
					type='submit'
				>
					Отправить
				</button>
			</form>
		</div>
	)
}

export default RentComponent
