import React, { useState } from 'react'
import './SliderPreview.sass'

const ImageSlider = ({ images }) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0)

	const onThumbnailClick = index => {
		setCurrentImageIndex(index)
	}

	const onNextImageClick = () => {
		setCurrentImageIndex((currentImageIndex + 1) % images.length)
	}

	return (
		<div className='image-slider'>
			<div className='image-slider__main-image'>
				{images.map((image, index) => (
					<img
						key={index}
						src={`https://evbulsun.site:9000/uploads/${image}`}
						// src={`https://evbulsun.site:9000/uploads/${image}`}
						alt={`Slide ${index + 1}`}
						onClick={onNextImageClick}
						className={index === currentImageIndex ? 'active' : ''}
					/>
				))}
			</div>
			<div className='image-slider__thumbnails'>
				{images.map((image, index) => (
					<img
						key={index}
						src={`https://evbulsun.site:9000/uploads/${image}`}
						// src={`https://evbulsun.site:9000/uploads/${image}`}
						alt={`Thumbnail ${index + 1}`}
						onClick={() => onThumbnailClick(index)}
						className={index === currentImageIndex ? 'active' : ''}
					/>
				))}
			</div>
		</div>
	)
}

export default ImageSlider
