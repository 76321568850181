import React from 'react';
import { Link } from 'react-router-dom';
import './404.scss';

function NotFoundPage() {
    return (
        <div className="not-found">
            <h1>404</h1>
            <h2>Page not found</h2>
            <p>The page you are looking for does not exist.</p>
            <Link to='/'>Go back to homepage</Link>
        </div>
    );
}

export default NotFoundPage;
