
import React from 'react';
import style from './About.module.sass'


function About() {


    return (
        <div className={style.about}>
            <h2>Добро пожаловать на наш сайт!</h2>
            <p> Мы рады представить наш новый проект, который был создан, чтобы помочь людям, которые ищут жилье для покупки или продажи. Наш сайт предоставляет уникальную возможность подать заявку на жилье, которое вы ищете, и получать предложения от владельцев недвижимости.</p>
            <p> Мы понимаем, что поиск и покупка жилья может быть трудным и долгим процессом. Часто покупатели тратят много времени и денег на поиски жилья, но не находят то, что им нужно. Наш проект решает эту проблему, предоставляя удобный и простой способ связаться с владельцами недвижимости.</p>
            <p> Чтобы воспользоваться нашим сервисом, вам нужно заполнить форму на нашем сайте, указав свои пожелания и требования к жилью. Мы обрабатываем вашу заявку и отправляем ее владельцам недвижимости, которые соответствуют вашим требованиям. Владельцы могут связаться с вами напрямую через наш сайт, отправив предложение на ваш email.</p>
            <p> Наш проект исключает возможность быть обманутыми, обзванивая несуществующие объявления. К вам будут поступать предложения от людей, которые хотят заключить сделку в кротчайшие сроки, именно поэтому они ищут другие источники продать квартиру. И это повлияет на цену в лучшую для вас сторону. Ведь большая часть объявлений размещается с мыслью: "Если дадут мою завышенную цену, то я продам".</p>
            <p> Мы гарантируем конфиденциальность и безопасность ваших данных, а также точность и актуальность предложений, получаемых вами. Мы заботимся о том, чтобы наши пользователи получали лучший сервис и оптимальные предложения на рынке недвижимости.</p>
            <p> Наш проект - это уникальная возможность для покупателей и продавцов недвижимости связаться друг с другом и быстро и эффективно провести сделку. Мы уверены, что наш сервис будет полезен для всех, кто ищет жилье или хочет продать свою недвижимость.</p>
            <p>
                Присоединяйтесь к нашему проекту и начните искать свое идеальное жилье уже сегодня!
            </p>
        </div>
    )
}

export default About