import axios from '../../axios';
import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import style from './Login.module.sass';


function RessetPass() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ressetOk, setRessetOk] = useState(false);



    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };



    const handleSubmit = (event) => {
        event.preventDefault();
        setFormErrors(validate());
        setIsSubmitting(true);
    };

    const validate = () => {
        let errors = {};

        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid';
        }

        if (!password) {
            errors.password = 'Password is required';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }

        return errors;
    };

    useEffect(() => {
        const submitForm = async () => {
            const formData = {
                email: email,
                password: password,
            };
            try {
                const res = await axios.patch('/auth/resetpass', formData);

                if (res.data.message === "Пароль успешно изменен") {
                    setRessetOk(true)
                } else {
                    alert("Не удалось сменить пароль");
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors, isSubmitting, password, email]);


    if (ressetOk) {
        return <Navigate to="/login" />;
    }


    return (
        <div className={style.registrationForm}>
            <form onSubmit={handleSubmit}>

                <label>
                    Email указанный при регистрации:
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    {formErrors.email && <span className="error">{formErrors.email}</span>}
                </label>
                <label>
                    Укажите новый пароль:
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    {formErrors.password && <span className="error">{formErrors.password}</span>}
                </label>

                <button className='btn' type="submit">изменить</button>
                <Link className="link" to='/resetpass'><div className={style.resetPassword}>Забыли пароль?</div></Link>
            </form>
        </div>
    );
}

export default RessetPass;
