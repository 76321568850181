import React, { useState } from 'react'

function Photocut() {
	const [files, setFiles] = useState([])
	const [nameTemplate, setNameTemplate] = useState('')

	const submit = async e => {
		e.preventDefault()
		const formData = new FormData()
		files.forEach((file, index) => {
			formData.append('images', file)
		})
		formData.append('nameTemplate', nameTemplate)

		// Отправляем форму на сервер
		const response = await fetch('http://localhost:7777/upload', {
			method: 'POST',
			body: formData,
		})

		const data = await response.json()
		console.log(data)
	}

	const onFileChange = e => {
		setFiles(Array.from(e.target.files))
	}

	const onNameTemplateChange = e => {
		setNameTemplate(e.target.value)
	}

	return (
		<div className='App'>
			<form onSubmit={submit}>
				<input type='file' multiple onChange={onFileChange} />
				<input
					type='text'
					placeholder='Name template'
					value={nameTemplate}
					onChange={onNameTemplateChange}
				/>
				<button type='submit'>Submit</button>
			</form>
		</div>
	)
}

export default Photocut
