import React, { useEffect } from 'react';
import './Widget.sass';

const TradingViewWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
            symbols: [
                {
                    description: '',
                    proName: 'BINANCE:BTCUSDT',
                },
                {
                    description: '',
                    proName: 'FX:USDTRY',
                },
                {
                    description: '',
                    proName: 'FX_IDC:USDRUB',
                },
                {
                    description: '',
                    proName: 'FX_IDC:USDKZT',
                },
                {
                    description: '',
                    proName: 'FX_IDC:USDUAH',
                },
                {
                    description: '',
                    proName: 'BITTREX:USDEUR',
                },
            ],
            showSymbolLogo: false,
            colorTheme: 'light',
            isTransparent: false,
            displayMode: 'adaptive',
            locale: 'ru',
        });

        document.getElementById('tradingview-widget-container').appendChild(script);

        return () => {
            document.getElementById('tradingview-widget-container').removeChild(script);
        };
    }, []);

    return (
        <div className="tradingview-widget-container" id="tradingview-widget-container">
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright">
                <a href="https://ru.tradingview.com/markets/" rel="noreferrer" target="_blank">
                    <span className="blue-text">Котировки</span>
                </a>{' '}

            </div>
        </div>
    );
};

export default TradingViewWidget;
