import React, { useState } from 'react';
import Helmet from 'react-helmet';
import './CheckHouse.sass';

function CheckHouse() {
    const [openAccordion, setOpenAccordion] = useState(null);

    const handleAccordionClick = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };

    return (
        <div className='CheckHouse'>
            <Helmet>
                <title>Используйте нашу услугу для проверки квартиры перед покупкой</title>
                <meta name="Проверьте квартиру перед покупкой вместе с нашей услугой! Экспертное мнение о состоянии объекта, фото- и видеодоказательства, проверка документов. Гарантированная безопасность и качество услуг. Свяжитесь с нами уже сегодня и получите полную информацию о квартире перед ее покупкой!" />
            </Helmet>
            <h1 className='CheckHouse-title'>
                Готовы купить свою мечту? <br /> Используйте нашу услугу для проверки квартиры перед покупкой.
            </h1>
            <div className='CheckHouse-descr-wrap'>
                <h3 className='CheckHouse-descr-title'>Желающим купить или снять квартиру.</h3>
                <div>
                    <ul className='CheckHouse-descr'>
                        <li className='CheckHouse-descr-li' key={0}>
                            <button onClick={() => handleAccordionClick(0)}>Цена от 50$</button>

                            <ul>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 0 ? 'CheckHouse-descr-answer-active' : ''}`}>Зависит от удаленности квартиры от нашего офиса и квадратуры</li>
                            </ul>

                        </li>
                        <li className='CheckHouse-descr-li' key={1}>
                            <button onClick={() => handleAccordionClick(1)}>Что входит в проверку?</button>

                            <ul>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Электрика</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Водоснабжение</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Качество ремонта</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Качество окон и дверей</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Сантехника</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Чистота и запахи</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Плесень и сырость</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Интерьер</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Проверка фактической квадратуры</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Проверим, как далеко до моря и на сколько легко добираться</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Инфраструктура и окружение</li>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>Также, Вы можете добавить любой пункт при обсуждении по телефону</li>

                            </ul>

                        </li>
                        <li className='CheckHouse-descr-li' key={2}>
                            <button onClick={() => handleAccordionClick(2)}>Могу ли я доверять проверке?</button>

                            <ul>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 2 ? 'CheckHouse-descr-answer-active' : ''}`}>По окончании проверки, Вам будут переданы все материалы, в том числе фото и видео.</li>
                            </ul>

                        </li>
                        <li className='CheckHouse-descr-li' key={3}>
                            <button onClick={() => handleAccordionClick(3)}>Зачем мне это?</button>

                            <ul>
                                <li className={`CheckHouse-descr-answer ${openAccordion === 3 ? 'CheckHouse-descr-answer-active' : ''}`}>Услуга будет ценна для тех, кто ищет квартиру заранее и находится далеко, особенно для людей, которые не говорят на турецком языке.</li>
                            </ul>

                        </li>
                    </ul>
                    <div className='CheckHouse-descr-rent-wrapp'>
                        <h3 className='CheckHouse-descr-title'>Желающим продать или сдать квартиру.</h3>
                        <p className='CheckHouse-descr-rent-descr'>
                            К объявлениям, которые прошли проверку, будет присвоен знак "проверка пройдена".
                        </p>
                        <div className='CheckHouse-descr-rent-advantages'>
                            <p className='CheckHouse-descr-rent-list-title'>Присвоение знака "проверка пройдена" объявлению, которое успешно прошло проверку, имеет следующие преимущества:</p>
                            <ul>
                                <li className=' CheckHouse-descr-rent-advantages-li bg'>Увеличивает доверие потенциальных клиентов к объявлению и к агентству, проводившему проверку.

                                </li>
                                <li className=' CheckHouse-descr-rent-advantages-li'>
                                    Указывает на то, что объявление соответствует стандартам качества и безопасности.
                                </li>
                                <li className=' CheckHouse-descr-rent-advantages-li bg'>
                                    Помогает отличить проверенные объявления от непроверенных, что упрощает поиск жилья для клиентов.
                                </li>
                                <li className=' CheckHouse-descr-rent-advantages-li'>
                                    Создает дополнительный привлекательный маркетинговый элемент для объявления, который может привести к большей заинтересованности со стороны потенциальных клиентов.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <a className='link-text-decor-none' href="https://wtsp.cc/905384315894"><div className="btn CheckHouse-btn">перейти на whatsapp</div></a>
        </div>
    );
}

export default CheckHouse;
