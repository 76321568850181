import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import axios from '../../axios'
import style from './BuyItem.module.sass'

function ArticleFull() {
	const { id } = useParams()
	const [data, setData] = useState([])

	useEffect(() => {
		axios
			.get(`/article/${id}`)
			.then(res => {
				setData(res.data)
			})
			.catch(err => {
				console.log(err)
				alert('Ошибка сервера')
			})
	}, [id])

	return (
		<>
			<div className={style.buyItem}>
				<div>
					<FontAwesomeIcon icon={faEye} />
					{data.viewsCount}
				</div>
				<img
					src={`https://evbulsun.site:9000/uploads/${data.image}`}
					alt='изображение'
				/>
				<h2>{data.title}</h2>
				<div className={style.description}>
					<ReactMarkdown>{data.description}</ReactMarkdown>
				</div>
			</div>
		</>
	)
}

export default ArticleFull
