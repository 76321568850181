import axios from 'axios'

const instance = axios.create({
	// baseURL: 'http://localhost:4444',

	baseURL: 'https://evbulsun.site:9000', // for server ыdigitalOcean
})

instance.interceptors.request.use(config => {
	config.headers.Authorization = window.localStorage.getItem('token')
	return config
})

export default instance
