import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectIsAuth } from '../../Redux/LoginSlice'
import axios from '../../axios'
import Loader from '../Loader'
import Popup2 from '../Popup2/Popap'
import style from './BuyItem.module.sass'

function RealEstateItem() {
	const { id } = useParams()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [activated, setActivated] = useState('')

	useEffect(() => {
		axios
			.get(`/buyer-item/${id}`)
			.then(res => {
				setData(res.data)
			})
			.catch(err => {
				console.log(err)
				alert('Ошибка сервера')
			})
	}, [id])

	const [formData, setFormData] = useState({
		link: '',
		price: '',
		description: '',
		phoneNumber: '',
		images: null,
		cardsEmail: '',
	})

	const handleChange = event => {
		const { name, value } = event.target
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}))
		if (data.user.email) {
			setFormData(prevData => ({
				...prevData,
				cardsEmail: data.user.email,
			}))
		} else {
			setFormData(prevData => ({
				...prevData,
				cardsEmail: 'tehnoshark2284@gmail.com',
			}))
		}
	}

	const handleImageChange = async event => {
		try {
			setLoading(true)
			const { files } = event.target
			const newFileNames = []
			const imgData = new FormData()
			setFormData(prevData => ({
				...prevData,
				images: [],
			}))

			for (let i = 0; i < files.length; i++) {
				const newFilename =
					Math.floor(Math.random() * 1000000000) +
					'.' +
					files[i].name.split('.').pop() // Генерация нового имени файла
				newFileNames.push(newFilename)
				const blob = new Blob([files[i]], { type: files[i].type }) // Создание Blob с исходным типом файла
				imgData.append('image', blob, newFilename) // Добавление Blob с новым именем файла
			}

			await axios.post('/upload', imgData)

			setFormData(prevData => ({
				...prevData,
				images: [...prevData.images, ...newFileNames],
			}))
		} catch (error) {
			console.warn(error)
			alert('Ошибка!!! Возможно файл слишком большой')
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async event => {
		event.preventDefault()

		await axios
			.post(`/buyitem`, formData)
			.then(res => {
				alert('Ваше предложение успешно отправленно')
				setFormData({
					link: '',
					price: '',
					phoneNumber: '',
					images: null,
				})
			})
			.catch(err => {
				console.log(err)
				alert('Пожалуйста убедитесь что вы вошли в аккаунт')
			})
	}

	const isAuth = useSelector(selectIsAuth)
	useEffect(() => {
		if (isAuth) {
			const { isActivated } = isAuth
			setActivated(isActivated)
		}
	}, [isAuth])

	return (
		<>
			<Helmet>
				<title>
					Предложите свою квартиру для продажи в ответ на объявление о покупке.
				</title>
				<meta
					name='description'
					content='Предложите свою квартиру для продажи в ответ на объявление о покупке на нашем сайте. Заполните форму и получите возможность продать вашу квартиру быстро и по выгодной цене. Наши услуги помогут вам найти покупателя и провести сделку в кратчайшие сроки.'
				/>
			</Helmet>
			<div className={style.buyItemWrapp}>
				{activated ? null : <Popup2 />}
				<div className={style.buyItem}>
					<div className={style.cartHeader}>
						<div className={style.cartHeaderDescr}>Описание</div>
						<div className={style.cartHeaderRoomCount}>Комнат</div>
						<div className={style.cartHeaderIsPawned}>Под ВНЖ?</div>
						<div className={style.cartHeaderPrice}>Цена $</div>
						<div className={style.cartHeaderCity}>Город</div>
					</div>
					<div className={style.cartWrapp}>
						<div className={style.cart}>
							<div className={style.cartDescr}>{data.description}</div>
							<div className={style.cartRoomCount}>{data.roomCount}</div>
							<div className={style.cartIsPawned}>{data.isPawned}</div>
							<div className={style.cartPrice}>{data.price}</div>
							<div className={style.cartCity}>{data.city}</div>
						</div>
						<div className={style.cartDescrMedia}>
							<div>{data.description}</div>
						</div>
					</div>
					<div className={style.faEye}>
						<FontAwesomeIcon icon={faEye} />
						{data.viewsCount}
					</div>
				</div>
				<div className={`${style.apartmentForm} ${'form'}`}>
					<h2 className={style.title}>
						Форма для отправки предложения искателю квартиры
					</h2>
					<form onSubmit={handleSubmit}>
						<label>
							<p>Ссылка на объявление:</p>
							<input
								type='text'
								placeholder='Пример: https://www.sahibinden.com/ilan/emlak'
								name='link'
								value={formData.link}
								onChange={handleChange}
								required
							/>
						</label>
						<br />
						<label>
							<p>Цена $:</p>
							<input
								type='text'
								name='price'
								value={formData.price}
								onChange={handleChange}
								required
							/>
						</label>
						<br />
						<label>
							<p>Описание:</p>
							<textarea
								name='description'
								value={formData.description}
								onChange={handleChange}
								required
							/>
						</label>
						<br />
						<label>
							<p>Телефон:</p>
							<input
								type='text'
								name='phoneNumber'
								value={formData.phoneNumber}
								onChange={handleChange}
								required
							/>
						</label>
						<br />
						<label>
							<p>Фотография:</p>
							{loading ? <Loader /> : null}
							<input
								type='file'
								name='images'
								onChange={handleImageChange}
								multiple
							/>
						</label>
						<br />
						<button disabled={loading} className='btn' type='submit'>
							отправить
						</button>
					</form>
				</div>
			</div>
		</>
	)
}

export default RealEstateItem
