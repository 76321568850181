import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchAuth, selectIsAuth } from '../../Redux/LoginSlice'
import { fetchRegister } from '../../Redux/RegSlice'
import axios from '../../axios'
import Loader from '../Loader'
import Popup from '../Popup/homePopup'
import RegistrationForm from '../RegistrationsForm'
import style from '../RegistrationsForm/RegistrationsForm.module.sass'
import './ToAdvertiseRental.sass'

function ToAdvertiseRental() {
	const dispathc = useDispatch()

	const [loading, setLoading] = useState(false)

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [formErrors, setFormErrors] = useState({})
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenPopup, setIsOpenPopup] = useState(false)
	const [isToastVisible, setToastVisible] = useState(false)
	const [dataPageItem, setDataPageItem] = useState('')
	const handleNameChange = event => {
		setName(event.target.value)
	}

	const handleEmailChange = event => {
		setEmail(event.target.value)
	}

	const handlePasswordChange = event => {
		setPassword(event.target.value)
	}

	const handleConfirmPasswordChange = event => {
		setConfirmPassword(event.target.value)
	}

	const validate = () => {
		let errors = {}

		if (!name.trim()) {
			errors.name = 'Name is required'
		}

		if (!email.trim()) {
			errors.email = 'Email is required'
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = 'Email address is invalid'
		}

		if (!password) {
			errors.password = 'Password is required'
		} else if (password.length < 6) {
			errors.password = 'Password must be at least 6 characters'
		}

		if (password !== confirmPassword) {
			errors.confirmPassword = 'Passwords do not match'
		}

		return errors
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	const [activated, setActivated] = useState(false)
	const [adData, setAdData] = useState({
		city: '',
		rooms: '',
		type: '',
		district: '',
		additionalInfo: '',
		images: [],
		districts: [],
		propertyType: '',
		kitchenType: '',
		totalFloors: '',
		currentFloor: '',
		furniture: '',
		price: '',
		currency: '',
		address: '',
		area: '',
		phone: '',
		howlong: '',
	})

	const districts = {
		İstanbul: [
			'Adalar',
			'Arnavutköy',
			'Ataşehir',
			'Avcılar',
			'Bağcılar',
			'Bahçelievler',
			'Bakırköy',
			'Başakşehir',
			'Bayrampaşa',
			'Beşiktaş',
			'Beykoz',
			'Beylikdüzü',
			'Beyoğlu',
			'Büyükçekmece',
			'Çatalca',
			'Çekmeköy',
			'Esenler',
			'Esenyurt',
			'Eyüpsultan',
			'Fatih',
			'Gaziosmanpaşa',
			'Güngören',
			'Kadıköy',
			'Kağıthane',
			'Kartal',
			'Küçükçekmece',
			'Maltepe',
			'Pendik',
			'Sancaktepe',
			'Sarıyer',
			'Şile',
			'Silivri',
			'Şişli',
			'Sultanbeyli',
			'Sultangazi',
			'Tuzla',
			'Ümraniye',
			'Üsküdar',
			'Zeytinburnu',
		],

		Antalya: [
			'Altıntaş',
			'Aksu',
			'Döşemealtı',
			'Kepez',
			'Konyaaltı',
			'Muratpaşa',
		],
		Alanya: [
			'Оба',
			'Махмутлар',
			'Каргыджак',
			'Авсаллар',
			'Джикджилли',
			'Демирташ',
			'Конаклы',
			'Кестель',
			'Тосмур',
			'Газипаша',
			'Окурджалар',
			'Паяллар',
		],
		Bursa: [
			'Osmangazi',
			'Nilüfer',
			'Büyükorhan',
			'Yıldırım',
			'Gemlik',
			'Gürsu',
			'Harmancık',
			'İnegöl',
			'İznik',
			'Karacabey',
			'Keles',
			'Kestel',
			'Mudanya',
			'Mustafakemalpaşa',
			'Orhaneli',
			'Orhangazi',
			'Yenişehir',
		],
		İzmir: [
			'Aliağa',
			'Balçova',
			'Bayındır',
			'Bayraklı',
			'Bergama',
			'Beydağ',
			'Bornova',
			'Buca',
			'Çeşme',
			'Çiğli',
			'Dikili',
			'Foça',
			'Gaziemir',
			'Güzelbahçe',
			'Karabağlar',
			'Karaburun',
			'Karşıyaka',
			'Kemalpaşa',
			'Kınık',
			'Kiraz',
			'Konak',
			'Menderes',
			'Menemen',
			'Narlıdere',
			'Ödemiş',
			'Seferihisar',
			'Selçuk',
			'Tire',
			'Torbalı',
			'Urla',
		],
		// добавьте другие города и их районы здесь...
	}

	const handleInputChange = event => {
		if (event.target.name === 'city') {
			setAdData({
				...adData,
				[event.target.name]: event.target.value,
				districts: districts[event.target.value] || [],
				district: '',
			})
		} else if (event.target.name === 'images') {
			setAdData({
				...adData,
				[event.target.name]: event.target.files,
			})
		} else {
			setAdData({
				...adData,
				[event.target.name]: event.target.value,
			})
		}
	}

	const handleImageChange = async event => {
		try {
			setLoading(true)
			const { files } = event.target
			const newFileNames = []
			const imgData = new FormData()

			for (let i = 0; i < files.length; i++) {
				const newFilename =
					Math.floor(Math.random() * 1000000000) +
					'.' +
					files[i].name.split('.').pop() // Генерация нового имени файла
				newFileNames.push(newFilename)
				const blob = new Blob([files[i]], { type: files[i].type }) // Создание Blob с исходным типом файла
				imgData.append('image', blob, newFilename) // Добавление Blob с новым именем файла
			}
			setAdData(prevData => ({
				...prevData,
				images: [...prevData.images, ...newFileNames],
			}))

			await axios.post('/upload', imgData)
		} catch (error) {
			console.warn(error)
			alert('Ошибка!!! Возможно файл слишком большой')
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async event => {
		event.preventDefault()
		setFormErrors(validate())

		if (!activated) {
			const regFormData = {
				name: name,
				email: email,
				password: password,
			}

			try {
				const res = await dispathc(fetchRegister(regFormData))

				if (res.meta.requestStatus === 'fulfilled') {
					const loginFormData = {
						email: email,
						password: password,
					}
					const data = await dispathc(fetchAuth(loginFormData))
					if (!data.payload) {
						alert('Не удалось авторизоваться')
					}
					if ('token' in data.payload) {
						window.localStorage.setItem('token', data.payload.token)
					}
					const response = await axios.post('/to-advertise-rental', adData)

					setDataPageItem(response.data._id)
					setIsOpenPopup(true)
					fetch(
						`https://api.telegram.org/bot5953316413:AAEcTStqojzvX7VZFD1eN0dmaAS9VdfAnjA/sendMessage?chat_id=-1001906403850&text= СДАМ АРЕНДА / ${adData.type} / ${adData.propertyType}  в  ${adData.city} / Количество комнат  ${adData.rooms} /Кухня ${adData.kitchenType}/ ${adData.furniture} /ОСНОВНОЕ ОПИСАНИЕ  ${adData.additionalInfo} / Цена  ${adData.price} ${adData.currency} / Телефон ${adData.phone} / ${adData._id} / Ссылка https://evbulsun.site/seller-datapage-rental-item/${response.data._id}`
					)
				} else {
					alert('Ошибка при регистрации или заполнения формы!!!')
				}
			} catch (error) {
				console.log(error)
			}
		} else {
			const response = await axios.post('/to-advertise-rental', adData)
			setDataPageItem(response.data._id)
			fetch(
				`https://api.telegram.org/bot5953316413:AAEcTStqojzvX7VZFD1eN0dmaAS9VdfAnjA/sendMessage?chat_id=-1001906403850&text= СДАМ АРЕНДА / ${adData.type} / ${adData.propertyType}  в  ${adData.city} / Количество комнат  ${adData.rooms} /Кухня ${adData.kitchenType}/ ${adData.furniture} /ОСНОВНОЕ ОПИСАНИЕ  ${adData.additionalInfo} / Цена  ${adData.price} ${adData.currency} / Телефон ${adData.phone} / ${adData._id} / Ссылка https://evbulsun.site/seller-datapage-rental-item/${response.data._id}`
			)
			setIsOpenPopup(true)
		}
	}

	const isAuth = useSelector(selectIsAuth)
	useEffect(() => {
		if (isAuth) {
			setActivated(true)
		}
	}, [isAuth])
	const handleClosePopup = () => {
		setIsOpenPopup(false)
	}
	const handleCopy = async () => {
		try {
			const textToCopy = `https://evbulsun.site/seller-datapage-rental-item/${dataPageItem}`
			await navigator.clipboard.writeText(textToCopy)
			setToastVisible(true) // Показать тост
			setTimeout(() => setToastVisible(false), 1000) // Автоматически скрыть через 3 секунды
		} catch (err) {
			console.error('Не удалось скопировать текст: ', err)
		}
	}

	return (
		<div>
			<Popup isOpen={isOpenPopup} onClose={handleClosePopup}>
				<h2>Форма отправлена. 👍</h2>
				<h3>
					Ссылка на ваше объявление -{' '}
					<a
						href={`https://evbulsun.site:9000/seller-datapage-rental-item/${dataPageItem}`}
						onClick={e => {
							e.preventDefault()
							handleCopy()
						}}
					>
						https://evbulsun.site/seller-datapage-item/${dataPageItem}
					</a>
					{isToastVisible && (
						<div className='toast'>
							<h4>Ссылка скопирована в буфер обмена!</h4>
						</div>
					)}
				</h3>

				<Link
					className='btn'
					to={`/seller-datapage-rental-item/${dataPageItem}`}
				>
					<p onClick={() => setIsOpenPopup(false)}>Смотреть</p>
				</Link>
			</Popup>
			<h1 className='advertise-title'>
				Подать объявление о сдаче квартиры. Бесплатно.
			</h1>
			<div className='advertise-wrap'>
				<Helmet>
					<title>Подать объявление о сдаче квартиры бесплатно.</title>

					<meta
						name='description'
						content='Здесь вы можете разместить объявление о аренде квартиры в городах: Алания, Анкара, Анталия, Бурса, Демре, Эскишехир, Стамбул, Измир, Каш, Кемер, Конья, Манавгат, Мерсин, Серик. Бесплатная публикация объявлений, быстрый поиск покупателей.'
					/>
				</Helmet>

				<form
					className='advertise-form'
					onSubmit={handleSubmit}
					encType='multipart/form-data'
				>
					<label>
						Тип жилья:
						<select
							name='propertyType'
							value={adData.propertyType}
							onChange={handleInputChange}
						>
							<option value=''>Выберите...</option>
							<option value='Новостройка'>Новостройка</option>
							<option value='Вторичное жилье'>Вторичное жилье</option>
						</select>
					</label>

					<label>
						Город:
						<select
							name='city'
							value={adData.city}
							onChange={handleInputChange}
						>
							<option value=''>Выберите...</option>
							<option value='Alanya'>Алания</option>
							<option value='Ankara'>Анкара</option>
							<option value='Antalya'>Анталия</option>
							<option value='Bursa'>Бурса</option>
							<option value='Demre'>Демре</option>
							<option value='Eskişehir'>Эскишехир</option>
							<option value='İstanbul'>Стамбул</option>
							<option value='İzmir'>Измир</option>
							<option value='Kaş'>Каш</option>
							<option value='Kemer'>Кемер</option>
							<option value='Konya'>Конья</option>
							<option value='Manavgat'>Манавгат</option>
							<option value='Mersin'>Мерсин</option>
							<option value='Serik'>Серик</option>
						</select>
					</label>

					<label>
						Район:
						<select
							name='district'
							value={adData.district}
							onChange={handleInputChange}
						>
							<option value=''>Выберите...</option>
							{adData.districts.length > 0 ? (
								adData.districts.map((district, index) => (
									<option key={index} value={district}>
										{district}
									</option>
								))
							) : (
								<option value='' disabled>
									Нет доступных районов
								</option>
							)}
						</select>
					</label>

					<label>
						Количество комнат:
						<input
							type='number'
							name='rooms'
							value={adData.rooms}
							onChange={handleInputChange}
						/>
					</label>

					<label>
						Площадь (кв. м):
						<input
							type='number'
							name='area'
							value={adData.area}
							onChange={handleInputChange}
						/>
					</label>

					<label>
						Тип квартиры:
						<select
							name='type'
							value={adData.type}
							onChange={handleInputChange}
						>
							<option value=''>Выберите...</option>
							<option value='Квартира (апартаменты)'>
								Квартира (апартаменты)
							</option>
							<option value='Квартира (дуплекс)'>Квартира (дуплекс)</option>
							<option value='Дом (вилла)'>Дом (вилла)</option>
							<option value='Таунхаус'>Таунхаус</option>
							<option value='Ком. недвижимость'>Ком. недвижимость</option>
							<option value='Земельный участок'>Земельный участок</option>
						</select>
					</label>
					<label className='kitchenType'>
						Тип кухни:
						<label>
							<input
								type='radio'
								name='kitchenType'
								value='Студия'
								checked={adData.kitchenType === 'Студия'}
								onChange={handleInputChange}
							/>
							Студия
						</label>
						<label>
							<input
								type='radio'
								name='kitchenType'
								value='Отдельная кухня'
								checked={adData.kitchenType === 'Отдельная кухня'}
								onChange={handleInputChange}
							/>
							Отдельная кухня
						</label>
					</label>
					<label>
						Количество этажей в здании:
						<input
							type='number'
							name='totalFloors'
							value={adData.totalFloors}
							onChange={handleInputChange}
						/>
					</label>

					<label>
						Ваш этаж:
						<input
							type='number'
							name='currentFloor'
							value={adData.currentFloor}
							onChange={handleInputChange}
						/>
					</label>

					<label className='furniture'>
						Наличие мебели:
						<div>
							<label>
								<input
									type='radio'
									name='furniture'
									value='с мебелью'
									checked={adData.furniture === 'с мебелью'}
									onChange={handleInputChange}
								/>
								С мебелью
							</label>
							<label>
								<input
									type='radio'
									name='furniture'
									value='без мебели'
									checked={adData.furniture === 'без мебели'}
									onChange={handleInputChange}
								/>
								Без мебели
							</label>
						</div>
					</label>

					<label className='furniture'>
						По суточно или короткий срок:
						<div>
							<label>
								<input
									type='radio'
									name='howlong'
									value='ДА'
									checked={adData.howlong === 'ДА'}
									onChange={handleInputChange}
								/>
								ДА
							</label>
							<label>
								<input
									type='radio'
									name='howlong'
									value='НЕТ'
									checked={adData.howlong === 'НЕТ'}
									onChange={handleInputChange}
								/>
								НЕТ
							</label>
						</div>
					</label>

					<label className='additionalInfo'>
						Дополнительная информация:
						<textarea
							name='additionalInfo'
							value={adData.additionalInfo}
							onChange={handleInputChange}
						/>
					</label>

					<label>
						Фотографии:
						{loading ? <Loader /> : null}
						<input
							type='file'
							name='images'
							onChange={handleImageChange}
							multiple
						/>
					</label>

					<label>
						<div>
							Адрес: (вставьте ссылку из{' '}
							<a
								href='https://www.google.com/maps/@36.8902144,30.6937856,13z?entry=ttu'
								target='_blank'
								rel='noreferrer'
							>
								Google Maps
							</a>
							)
						</div>

						<input
							type='text'
							name='address'
							value={adData.address}
							onChange={handleInputChange}
						/>
					</label>

					<label>
						Цена:
						<input
							type='number'
							name='price'
							value={adData.price}
							onChange={handleInputChange}
						/>
					</label>

					<label>
						Валюта:
						<select
							name='currency'
							value={adData.currency}
							onChange={handleInputChange}
						>
							<option value=''>Выберите...</option>
							<option value='USD'>USD</option>
							<option value='EUR'>EUR</option>
							<option value='TRY'>TRY</option>
						</select>
					</label>

					<label>
						Телефон:
						<input
							type='tel'
							name='phone'
							value={adData.phone}
							onChange={handleInputChange}
						/>
					</label>

					{activated ? null : (
						<div className={style.registrationFormWrap}>
							<h1 className={style.registrationFormTitle}>
								Ускорьте процесс поиска или продажи жилья - зарегистрируйтесь
								сейчас! Получайте предложения от заинтересованных людей,
								соответствующие вашим критериям. Регистрация займет всего
								минуту.
							</h1>
							<div className={style.registrationForm}>
								<RegistrationForm
									isOpen={isOpen}
									handleClose={handleClose}
									handleSubmit={handleSubmit}
									handleNameChange={handleNameChange}
									handleEmailChange={handleEmailChange}
									handlePasswordChange={handlePasswordChange}
									handleConfirmPasswordChange={handleConfirmPasswordChange}
									formErrors={formErrors}
									name={name}
									email={email}
									password={password}
									confirmPassword={confirmPassword}
								/>
							</div>
						</div>
					)}

					<div className='ToAdvertise-btn'>
						<button disabled={loading} type='submit'>
							Разместить объявление
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default ToAdvertiseRental
