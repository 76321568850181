import { GoogleOAuthProvider } from '@react-oauth/google'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import './App.sass'
import Photocut from './Components/Obrezalka/PhotoCut'
import NotFoundPage from './Components/Pages/404/404'
import About from './Components/Pages/AbouPproject'
import AllReviews from './Components/Pages/AllReviews'
import Article from './Components/Pages/Article'
import ArticleFull from './Components/Pages/ArticleFull'
import ArticlePost from './Components/Pages/ArticlePost'
import BuyForm from './Components/Pages/BuyForm/BuyForm'
import BuyItem from './Components/Pages/BuyItem'
import Cabinet from './Components/Pages/Cabinet'
import CheckHouse from './Components/Pages/CheckHouse'
import DataPage from './Components/Pages/DataPage'
import DataPageRental from './Components/Pages/DataPageRental'
import Footer from './Components/Pages/Footer'
import GPT3Chatbot from './Components/Pages/GptChat'
import Header from './Components/Pages/Header'
import Home from './Components/Pages/Home'
import LoginForm from './Components/Pages/Login'
import Registrations from './Components/Pages/Registrations/'
import RentComponent from './Components/Pages/Rent'
import RentItem from './Components/Pages/RentItem'
import RentRequest from './Components/Pages/RentRequest'
import RentalDataPageItem from './Components/Pages/RentalDataPageItem'
import RentalOwner from './Components/Pages/RentalOwner'
import RentalOwnerItem from './Components/Pages/RentalOwnerItem'
import RealEstate from './Components/Pages/Request'
import RessetPass from './Components/Pages/RessetPass'
import Reviews from './Components/Pages/Reviews/Reviews'
import SellApartment from './Components/Pages/SellApartment'
import Seller from './Components/Pages/Seller'
import SellerDataPageItem from './Components/Pages/SellerDataPageItem'
import SellerOwnerItem from './Components/Pages/SellerOwnerItem'
import ToAdvertise from './Components/Pages/ToAdvertise'
import ToAdvertiseRental from './Components/Pages/ToAdvertiseRental'
import Token from './Components/Pages/Token'
import ChatBotPage from './Components/Pages/VnjChatBot/ChatBotPage '
import Politica from './Components/Politica'
import { fetchAuthMe } from './Components/Redux/LoginSlice'
import ScrollToTop from './ScrollToTop'

function App() {
	const dispathc = useDispatch()

	React.useEffect(() => {
		dispathc(fetchAuthMe())
	}, [dispathc])

	return (
		<GoogleOAuthProvider clientId='131747928057-ahr9dmuef089n9c92pjvj0sfnmb2j0kb.apps.googleusercontent.com'>
			<div className='App'>
				<ScrollToTop />
				<Header />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/buy-form' element={<BuyForm />} />
					<Route path='/reg' element={<Registrations />} />
					<Route path='/login' element={<LoginForm />} />
					<Route path='/request' element={<RealEstate />} />
					<Route path='/rent-request' element={<RentRequest />} />
					<Route path='/buy-item/:id' element={<BuyItem />} />
					<Route path='/rent-item/:id' element={<RentItem />} />
					<Route path='/seller-owner-item/:id' element={<SellerOwnerItem />} />
					<Route path='/rental-owner-item/:id' element={<RentalOwnerItem />} />
					<Route path='/seller' element={<Seller />} />
					<Route path='/rental-owner' element={<RentalOwner />} />
					<Route path='/rent-form' element={<RentComponent />} />
					<Route path='/article' element={<Article />} />
					<Route path='/article-post' element={<ArticlePost />} />
					<Route path='/article-full/:id' element={<ArticleFull />} />
					<Route path='/about' element={<About />} />
					<Route path='/reviews' element={<Reviews />} />
					<Route path='/cabinet' element={<Cabinet />} />
					<Route path='/all-reviews' element={<AllReviews />} />
					<Route path='/resetpass' element={<RessetPass />} />
					<Route path='/politic' element={<Politica />} />
					<Route path='/*' element={<NotFoundPage />} />
					<Route path='/check-house' element={<CheckHouse />} />
					<Route path='/token' element={<Token />} />
					<Route path='/sell-apartment' element={<SellApartment />} />
					<Route path='/chat-bot' element={<GPT3Chatbot />} />
					<Route path='/to-advertise' element={<ToAdvertise />} />
					<Route path='/to-advertise-rental' element={<ToAdvertiseRental />} />
					<Route path='/data-page' element={<DataPage />} />
					<Route path='/data-page-rental' element={<DataPageRental />} />
					<Route
						path='/seller-datapage-item/:id'
						element={<SellerDataPageItem />}
					/>
					<Route
						path='/seller-datapage-rental-item/:id'
						element={<RentalDataPageItem />}
					/>
					<Route path='/photocut' element={<Photocut />} />
					<Route path='/vnjchatbot' element={<ChatBotPage />} />
				</Routes>

				<Footer />
			</div>
		</GoogleOAuthProvider>
	)
}

export default App
