import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectIsAuth } from '../../Redux/LoginSlice'
import axios from '../../axios'
import Popup from '../Popup/homePopup'
import TelegramWidget from '../TelegramWidget'
import style from './Request.module.sass'

function RentRequest() {
	const [city, setCity] = useState('')
	const [roomCount, setRoomCount] = useState('')
	const [isPawned, setIsPawned] = useState('')
	const [data, setData] = useState([])
	const [trashOn, setTrashOn] = useState(false)
	const [itemDeleted, setItemDeleted] = useState(null)
	const [isOpen, setIsOpen] = useState(false)

	const isAuth = useSelector(selectIsAuth)
	useEffect(() => {
		if (isAuth) {
			const { email } = isAuth
			if (email === 'tehnoshark2284@gmail.com') {
				setTrashOn(true)
			}
		}
	}, [isAuth])

	const handleCityChange = event => {
		setCity(event.target.value)
	}

	const handleRoomCountChange = event => {
		setRoomCount(event.target.value)
	}

	const handlePawnedChange = event => {
		setIsPawned(event.target.value)
	}

	const filterData = data => {
		let filteredData = data

		if (city) {
			filteredData = filteredData.filter(item => item.city === city)
		}

		if (roomCount) {
			filteredData = filteredData.filter(item => item.roomCount === roomCount)
		}

		if (isPawned) {
			filteredData = filteredData.filter(item => item.isPawned === isPawned)
		}

		return filteredData
	}

	useEffect(() => {
		const fetchData = async () => {
			const result = await axios.get('/rent')

			setData(result.data)
		}
		fetchData()
	}, [])

	const Trash = async (id, index) => {
		await axios.delete(`/rent/${id}`)
		setItemDeleted(index)
	}
	const handleOpen = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<>
			<div className={style.request}>
				<Helmet>
					<title>Объявления желающих арендовать квартиру</title>
					<meta
						name='description'
						content='Сдайте свою квартиру быстро и выгодно! На нашей платформе вы найдете потенциальных клиентов, которые заинтересованы в аренде квартиры в вашем районе.'
					/>
				</Helmet>
				<button
					onClick={handleOpen}
					className={`${style.homeBeginBtn} ${'homeBeginBtn'}`}
				>
					найти квартиру
				</button>

				<Popup isOpen={isOpen} onClose={handleClose}>
					<h2>Пожалуйста выберите</h2>
					<Link className='btn' to='/data-page-rental'>
						<p>Смотреть объявления</p>
					</Link>
					<p>Тут все обЪявления от хозяев квартиры</p>
					<Link className='btn' to='/rent-form'>
						<p>Подать объявление</p>
					</Link>
					<p>
						Тут можно дать обЪявление о желании арендовать квартиру, хозяева
						сами ищут Вас
					</p>
				</Popup>
				<h2>Все объявления желающих арендовать квартиру</h2>
				<h3>Вверху списка самые новые</h3>
				<p>Объявления удоляются через 7 дней</p>
				<TelegramWidget />
				<div className={style.filterWrapp}>
					<div>
						<label htmlFor='city'>Город:</label>
						<select
							id='city'
							name='city'
							value={city}
							onChange={handleCityChange}
						>
							<option value=''>All</option>
							<option value='Анталия'>Анталия</option>
							<option value='Алания'>Алания</option>
							<option value='Бодрум'>Бодрум</option>
							<option value='Измир'>Измир</option>
							<option value='Сиде'>Сиде</option>
							<option value='Мерсин'>Мерсин</option>
							<option value='Манавгат'>Манавгат</option>
						</select>
					</div>
					<div>
						<label htmlFor='roomCount'>Комнат:</label>
						<select
							id='roomCount'
							name='roomCount'
							value={roomCount}
							onChange={handleRoomCountChange}
						>
							<option value=''>All</option>
							<option value='0+1'>0+1</option>
							<option value='1+1'>1+1</option>
							<option value='2+1'>2+1</option>
							<option value='3+1'>3+1</option>
							<option value='4+1'>4+1</option>
						</select>
					</div>
					<div>
						<label htmlFor='isPawned'>Под ВНЖ:</label>
						<select
							id='isPawned'
							name='isPawned'
							value={isPawned}
							onChange={handlePawnedChange}
						>
							<option value=''>All</option>
							<option value='yes'>Да</option>
							<option value='no'>Нет</option>
						</select>
					</div>
				</div>
			</div>
			<div className={style.requestCarts}>
				<div className={style.cartHeader}>
					<div className={style.cartHeaderDescr}>Описание</div>
					<div className={style.cartHeaderRoomCount}>Комнат</div>
					<div className={style.cartHeaderIsPawned}>Под ВНЖ?</div>
					<div className={style.cartHeaderIsLong}>Срок</div>
					<div className={style.cartHeaderPrice}>Цена $</div>
					<div className={style.cartHeaderCity}>Город</div>
					<div className={style.cartHeaderStar}>*</div>
				</div>
				{filterData(data)
					.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
					.map((item, index) => (
						<div key={index} className={style.cartWrapp}>
							{trashOn ? (
								<button
									onClick={() => Trash(item._id, index)}
									className={`${style.cartTrash} ${
										itemDeleted === index ? style.active : ''
									}`}
								>
									<FontAwesomeIcon icon={faTrash} />
								</button>
							) : null}

							<div className={style.cart}>
								<div className={style.cartDescr}>{item.description}</div>
								<div className={style.cartRoomCount}>{item.roomCount}</div>
								<div className={style.cartIsPawned}>{item.isPawned}</div>
								<div className={style.rentalPeriod}>{item.rentalPeriod}</div>
								<div className={style.cartPrice}>{item.price}</div>
								<div className={style.cartCity}>{item.city}</div>
								<div className={style.BodyBtn}>
									<Link
										className='link-text-decor-none'
										to={`/rent-item/${item._id}`}
									>
										<div className={style.buyItemBtn}>
											<p>предложить</p>
											<p>квартиру</p>
										</div>
									</Link>
								</div>
							</div>
							<div className={style.cartDescrMedia}>
								<div className={style.descriptionrMedia}>
									{item.description}
								</div>
								<div className={style.BodyBtn}>
									<Link
										className='link-text-decor-none'
										to={`/rent-item/${item._id}`}
									>
										<div className={style.buyItemBtn}>
											<p>предложить</p>
											<p>квартиру</p>
										</div>
									</Link>
								</div>
							</div>
						</div>
					))}
			</div>
		</>
	)
}

export default RentRequest
