import React, { useEffect, useState } from 'react'
import axios from '../../axios'
import Loader from '../Loader'
import style from './PopupCardEdit.module.sass'

function EditModal({ isOpen, onClose, adDataRes }) {
	const [adData, setAdData] = useState(adDataRes || {})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setAdData({ ...adDataRes })
	}, [adDataRes])

	const handleInputChange = event => {
		const { name, value } = event.target
		setAdData({ ...adData, [name]: value })
	}

	const handleImageChange = async event => {
		try {
			setLoading(true)
			const { files } = event.target
			const newFileNames = []
			const imgData = new FormData()

			for (let i = 0; i < files.length; i++) {
				const newFilename =
					Math.floor(Math.random() * 1000000000) +
					'.' +
					files[i].name.split('.').pop() // Генерация нового имени файла
				newFileNames.push(newFilename)
				const blob = new Blob([files[i]], { type: files[i].type }) // Создание Blob с исходным типом файла
				imgData.append('image', blob, newFilename) // Добавление Blob с новым именем файла
			}
			setAdData(prevData => ({
				...prevData,
				images: [...prevData.images, ...newFileNames],
			}))

			await axios.post('/upload', imgData)
		} catch (error) {
			console.warn(error)
			alert('Ошибка!!! Возможно файл слишком большой')
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async event => {
		event.preventDefault()

		try {
			if ('howlong' in adData) {
				const res = await axios.put(
					`/to-advertise-update-rentalcart/${adData._id}`,
					adData
				)
				if (res) {
					alert('Изменения Сохранены')
				}
			} else {
				const res = await axios.put(
					`/to-advertise-update-cart/${adData._id}`,
					adData
				)
				if (res) {
					alert('Изменения Сохранены')
				}
			}
		} catch (error) {
			console.error('Ошибка при получении данных объявления: ', error)
		}
	}

	const handleImageRemove = async imageToRemove => {
		setLoading(true)
		try {
			await axios.post('/remove-image', { imageName: imageToRemove })

			// Удаление изображения из состояния после успешного удаления на сервере
			setAdData(prevData => ({
				...prevData,
				images: prevData.images.filter(image => image !== imageToRemove),
			}))
		} catch (error) {
			console.error('Ошибка при удалении изображения: ', error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			{isOpen && (
				<div className={style.popup}>
					<div className={style.popupContent}>
						<h1 className='advertise-title-EditModal'>
							Редактировать объявление о продаже квартиры.
						</h1>
						<div className='advertise-wrap advertise-wrap-EditModal'>
							<form
								className='advertise-form advertise-form-EditModal'
								onSubmit={handleSubmit}
								encType='multipart/form-data'
							>
								<label className='additionalInfo additionalInfo-EditModal'>
									Дополнительная информация:
									<textarea
										name='additionalInfo'
										value={adData.additionalInfo}
										onChange={handleInputChange}
									/>
								</label>
								<label>
									Фотографии:
									{loading && <Loader />}
									<div className={style.img}>
										{adData.images &&
											adData.images.map((image, index) => (
												<div className={style.imgBtnWrap} key={index}>
													<img
														src={`https://evbulsun.site:9000/uploads/${image}`}
														alt='daire'
													/>
													<button
														className={style.cardEditImgBtn}
														type='button'
														onClick={() => handleImageRemove(image)}
													>
														x
													</button>
												</div>
											))}
									</div>
									<input
										type='file'
										name='images'
										onChange={handleImageChange}
										multiple
									/>
								</label>

								<label>
									Цена:
									<input
										type='number'
										name='price'
										value={adData.price}
										onChange={handleInputChange}
									/>
								</label>
								<button className={style.saveBtn} type='submit'>
									Save Changes
								</button>
							</form>
							<button className={style.closeBtn} onClick={onClose}>
								Close
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default EditModal
