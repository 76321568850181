import React from 'react';
import video from '../../img/Как пользоваться сайтом EvBulsun.mp4';
import './video.sass';

function VideoPlayer() {
    return (
        <div className='video'>
            <video src={video} controls />
        </div>
    );
}

export default VideoPlayer;
