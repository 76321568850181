import { configureStore } from '@reduxjs/toolkit';
import { loginReducer } from './LoginSlice';
import { RegistrReducer } from './RegSlice';




const store = configureStore({
    reducer:
    {
        reg: RegistrReducer,
        auth: loginReducer,

    }

});

export default store;
