import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from '../../axios'
import WhatsAppButton from '../WhatsApp'
import './ChatBotPage.sass'

const ChatBotPage = () => {
	const [input, setInput] = useState('')
	const [messages, setMessages] = useState([])
	const [loading, setLoading] = useState(false)

	const handleInputChange = event => {
		setInput(event.target.value)
	}

	const sendMessageToChatGPT = async (text, sender) => {
		const trimmedText = text.trim()
		if (trimmedText === '') return

		// Обновляем историю сообщений с новым вопросом пользователя
		setMessages(messages => [
			...messages,
			{ text: trimmedText, sender: sender },
		])

		if (sender === 'user') {
			setInput('')
		}

		try {
			setLoading(true)
			const response = await axios.post('/api/gpt3vnj', { input: trimmedText })
			const answer = response.data.replies[0]
			// Добавляем ответ бота в историю сообщений
			setMessages(messages => [...messages, { text: answer, sender: 'bot' }])
		} catch (error) {
			console.error(`Error while sending message to GPT-3 API: ${error}`)
			// В случае ошибки добавляем сообщение об ошибке в историю чата
			setMessages(messages => [
				...messages,
				{
					text: 'Произошла ошибка при получении ответа от бота.',
					sender: 'bot',
				},
			])
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = event => {
		event.preventDefault()
		sendMessageToChatGPT(input, 'user')
	}

	// Автоматическая прокрутка к последнему сообщению
	useEffect(() => {
		const chatContainer = document.querySelector('.chat-history')
		chatContainer.scrollTop = chatContainer.scrollHeight
	}, [messages])

	const quickQuestions = [
		'Какие документы нужны для получения ВНЖ в Турции?',
		'Сколько стоит краткосрочный ВНЖ?',
		'Какие последние изменения в выдаче ВНЖ?',
		'Сколько времени надо ждать ВНЖ',
	]

	return (
		<div className='ChatBotPage'>
			<Helmet>
				<title>
					Помощник ВНЖ в Турции - Получите быстрые ответы на ваши вопросы
				</title>
				<meta
					name='description'
					content='Ищете информацию по получению ВНЖ в Турции? Получите мгновенные ответы на свои вопросы от нашего виртуального помощника.'
				/>
				<meta
					name='keywords'
					content='ВНЖ, Турция, помощник ВНЖ, вопросы о ВНЖ, иммиграция в Турцию'
				/>
			</Helmet>
			<h1 className='ChatBotPageTitle'>Помощник в получении ВНЖ в Турции</h1>
			<form className='ChatBotPageForm' onSubmit={handleSubmit}>
				<div className='chat-history'>
					{messages.map((message, index) => (
						<div key={index} className={`message ${message.sender}`}>
							<span className={`message-sender ${message.sender}-sender`}>
								{message.sender === 'user' ? 'Вы:' : 'Бот:'}
							</span>
							<span className='message-text'>{message.text}</span>
						</div>
					))}
				</div>
				<input
					type='text'
					value={input}
					onChange={handleInputChange}
					placeholder='Напишите ваш вопрос здесь...'
					className='ChatBotPageInput'
				/>
				<button className='ChatBotPageBtn' type='submit' disabled={loading}>
					{loading ? 'Отправка...' : 'Отправить вопрос'}
				</button>
			</form>
			<div className='ChatBotPageQuickQuestions'>
				{quickQuestions.map((question, index) => (
					<button
						key={index}
						className='ChatBotPageQuickQuestionBtn'
						onClick={() => sendMessageToChatGPT(question, 'user')}
						disabled={loading}
					>
						{question}
					</button>
				))}
			</div>
			<p className='ChatBotPageIli'>ИЛИ</p>
			<WhatsAppButton
				phoneNumber='905444558407'
				message='Здравствуйте. Я ищу квартиру в аренду.'
			/>
		</div>
	)
}

export default ChatBotPage
