import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchRegister } from '../../Redux/RegSlice'
import Popup from '../Popup/homePopup'
import style from './Registrations.module.sass'

function RegistrationForm() {
	const dispathc = useDispatch()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [formErrors, setFormErrors] = useState({})
	const [isSubmitting, setIsSubmitting] = useState(false)
	// const [errorAlert, setErrorAlert] = useState('');

	const [isOpen, setIsOpen] = useState(false)

	const handleNameChange = event => {
		setName(event.target.value)
	}

	const handleEmailChange = event => {
		setEmail(event.target.value)
	}

	const handlePasswordChange = event => {
		setPassword(event.target.value)
	}

	const handleConfirmPasswordChange = event => {
		setConfirmPassword(event.target.value)
	}

	const handleSubmit = event => {
		event.preventDefault()
		setFormErrors(validate())
		setIsSubmitting(true)
	}

	const validate = () => {
		let errors = {}

		if (!name.trim()) {
			errors.name = 'Name is required'
		}

		if (!email.trim()) {
			errors.email = 'Email is required'
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = 'Email address is invalid'
		}

		if (!password) {
			errors.password = 'Password is required'
		} else if (password.length < 6) {
			errors.password = 'Password must be at least 6 characters'
		}

		if (password !== confirmPassword) {
			errors.confirmPassword = 'Passwords do not match'
		}

		return errors
	}

	useEffect(() => {
		const formData = {
			name: name,
			email: email,
			password: password,
		}
		const submitForm = async () => {
			try {
				const res = await dispathc(fetchRegister(formData))

				if (res.meta.requestStatus === 'fulfilled') {
					setIsOpen(true)
				} else {
					alert('Ошибка при регистрации')
				}
			} catch (error) {
				console.log(error)
			}
		}

		if (Object.keys(formErrors).length === 0 && isSubmitting) {
			submitForm()
		}
	}, [formErrors, isSubmitting, name, dispathc])

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<>
			<h1 className={style.registrationFormTitle}>
				Ускорьте процесс поиска или продажи жилья - зарегистрируйтесь сейчас!
				Получайте предложения от заинтересованных людей, соответствующие вашим
				критериям. Регистрация займет всего минуту.
			</h1>
			<div className={style.registrationForm}>
				<Popup isOpen={isOpen} onClose={handleClose}>
					<h2>Вы зарегистрировались 👍</h2>
					<h3>Пожалуйста проверьте почту и актевируйте аккаунт.</h3>

					<Link className='btn' to='/login'>
						<p>Войти</p>
					</Link>
				</Popup>

				<form onSubmit={handleSubmit}>
					<label>
						Имя:
						<input type='text' value={name} onChange={handleNameChange} />
						{formErrors.name && (
							<span className='error'>{formErrors.name}</span>
						)}
					</label>
					<label>
						Email:
						<input type='email' value={email} onChange={handleEmailChange} />
						{formErrors.email && (
							<span className='error'>{formErrors.email}</span>
						)}
					</label>
					<label>
						Пароль:
						<input
							type='password'
							value={password}
							onChange={handlePasswordChange}
						/>
						{formErrors.password && (
							<span className='error'>{formErrors.password}</span>
						)}
					</label>
					<label>
						Повторите пароль:
						<input
							type='password'
							value={confirmPassword}
							onChange={handleConfirmPasswordChange}
						/>
						{formErrors.confirmPassword && (
							<span className='error'>{formErrors.confirmPassword}</span>
						)}
					</label>
					<button className={`${style.regBtn} ${'btn'}`} type='submit'>
						регистрация
					</button>
				</form>
				<p className={style.privacy}>
					Регистрируясь вы соглашаетесь с политикой конфиденциальности.
				</p>
			</div>
		</>
	)
}

export default RegistrationForm
