import { faEdit, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../Redux/LoginSlice'
import axios from '../../axios'
import PopupCabinet from '../PopupCabinet'
import EditModal from '../PopupCardEdit/PopupCardEdit'
import PopupCards from '../PopupCards'
import '../RentalOwner/seller.sass'
import style from '../Request/Request.module.sass'
import ImageSlider from '../SliderPreview'

function Cabinet() {
	const [data, setData] = useState([])
	const [dataPage, setDataPage] = useState([])
	const [dataBuyItem, setDataBuyItem] = useState([])
	const [trashOn, setTrashOn] = useState('')
	const [itemDeleted, setItemDeleted] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenCabinet, setIsOpenCabinet] = useState(false)
	const [isToastVisible, setToastVisible] = useState(false)
	const [editableAd, setEditableAd] = useState(null)
	const [isEditModalOpen, setEditModalOpen] = useState(false)

	const [selectedCardIndex, setSelectedCardIndex] = useState(null)
	const [hiddenCards, setHiddenCards] = useState(
		Array(dataBuyItem.length).fill(false)
	)

	useEffect(() => {
		const fetchData = async () => {
			const buyeritem = await axios.get('/buyer-item')
			const rent = await axios.get('/rent')

			setData([...buyeritem.data, ...rent.data])
		}
		fetchData()
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				const buyitemResponse = await axios.get('/buyitem')
				const rentalOwnerResponse = await axios.get('/rental-owner')
				setDataBuyItem([...buyitemResponse.data, ...rentalOwnerResponse.data])
			} catch (err) {
				console.log(err)
				alert('Ошибка сервера')
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				const buyerData = await axios.get('/to-advertise')
				const rentData = await axios.get('/to-advertise-rental')
				setDataPage([...buyerData.data, ...rentData.data])
			} catch (err) {
				console.log(err)
				alert('Ошибка сервера')
			}
		}
		fetchData()
	}, [dataPage])

	const isAuth = useSelector(selectIsAuth)
	useEffect(() => {
		if (isAuth) {
			const { email } = isAuth
			setTrashOn(email)
		}
	}, [isAuth])

	const Trash = async (item, index) => {
		try {
			if ('rentalPeriod' in item) {
				await axios.delete(`/rent/${item._id}`)
			} else {
				await axios.delete(`/buyer-item/${item._id}`)
			}
			setItemDeleted(index)
			const buyeritem = await axios.get('/buyer-item')
			const rent = await axios.get('/rent')
			setData([...buyeritem.data, ...rent.data])
		} catch (error) {
			console.error(error)
		}
	}

	const Trash2 = async item => {
		try {
			if ('howlong' in item) {
				await axios.delete(`/to-advertise-rental-delete/${item._id}`)
			} else {
				await axios.delete(`/to-advertise-delete/${item._id}`)
			}

			const buyerData = await axios.get('/to-advertise')
			const rentData = await axios.get('/to-advertise-rental')
			setDataPage([...buyerData.data, ...rentData.data])
		} catch (error) {
			console.error(error)
		}
	}
	const photo = cardIndex => {
		setSelectedCardIndex(cardIndex)
		setIsOpen(true)
	}
	const photo1 = cardIndex => {
		setSelectedCardIndex(cardIndex)
		setIsOpenCabinet(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}
	const handleClose1 = () => {
		setIsOpenCabinet(false)
	}
	const options = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
		timeZone: 'Europe/Istanbul',
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}

	const hideCard = cardIndex => {
		setHiddenCards(prevHiddenCards => {
			const newHiddenCards = [...prevHiddenCards]
			newHiddenCards[cardIndex] = !newHiddenCards[cardIndex]
			return newHiddenCards
		})
	}
	const handleCopy = async path => {
		try {
			const textToCopy = `https://evbulsun.site${path}`
			await navigator.clipboard.writeText(textToCopy)
			setToastVisible(true) // Показать тост
			setTimeout(() => setToastVisible(false), 2000) // Автоматически скрыть через 3 секунды
		} catch (err) {
			console.error('Не удалось скопировать текст: ', err)
		}
	}

	const editAd = async item => {
		try {
			if ('howlong' in item) {
				const response = await axios.get(
					`/to-advertise-rental-item/${item._id}`
				)
				await setEditableAd(response.data)
			} else {
				const response = await axios.get(`/to-advertise-item/${item._id}`)
				await setEditableAd(response.data)
			}

			setEditModalOpen(true) // Откройте модальное окно
		} catch (error) {
			console.error('Ошибка при получении данных объявления: ', error)
		}
	}

	return (
		<>
			<div className={style.request}>
				<h2>Ваши заявки:</h2>
			</div>
			{
				<div className={`${style.requestCarts} ${style.cabinet}`}>
					<div className={style.cartHeader}>
						<div className={style.cartHeaderDescr}>Описание</div>
						<div className={style.cartHeaderRoomCount}>Комнат</div>
						<div className={style.cartHeaderIsPawned}>Под ВНЖ?</div>
						<div className={style.cartHeaderPrice}>Цена $</div>
						<div className={style.cartHeaderCity}>Город</div>
					</div>
					{data
						.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
						.map(
							(item, index) =>
								trashOn === item.user.email && (
									<div key={index} className={style.cartWrapp}>
										<button
											onClick={() => Trash(item, index)}
											className={`${style.cartTrash} ${
												itemDeleted === index ? style.active : ''
											}`}
										>
											<FontAwesomeIcon icon={faTrash} />
										</button>
										<div className={style.cart}>
											<div className={style.cartDescr}>{item.description}</div>
											<div className={style.cartRoomCount}>
												{item.roomCount}
											</div>
											<div className={style.cartIsPawned}>{item.isPawned}</div>
											<div className={style.cartPrice}>{item.price}</div>
											<div className={style.cartCity}>{item.city}</div>
										</div>
										<div className={style.cartDescrMedia}>
											<div>{item.description}</div>
										</div>
									</div>
								)
						)}
				</div>
			}
			<div className='last-requests last-requests-cabinet'>
				<h2>Вам предлагают:</h2>
				<div className='last-requests-wrap'>
					{dataBuyItem
						.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
						.map(
							(item, cardIndex) =>
								item.cardsEmail === trashOn && (
									<div
										key={item._id}
										className={`lastRequestCardsWrap ${
											hiddenCards[cardIndex] ? 'hide' : ''
										}`}
									>
										<button onClick={() => hideCard(cardIndex)}>
											<FontAwesomeIcon icon={faEyeSlash} />
										</button>
										<ul className='lastRequestCard'>
											<li className='imgLi'>
												{item.images ? (
													item.images.map((item, index) => (
														<img
															key={index}
															onClick={() => photo1(cardIndex)}
															src={`https://evbulsun.site:9000/uploads/${item}`}
															alt='Фото товара'
														/>
													))
												) : (
													<img
														src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
														alt='Фото товара'
													/>
												)}
											</li>

											<PopupCabinet
												isOpenCabinet={isOpenCabinet}
												onCloseCabinet={handleClose1}
											>
												{dataBuyItem[selectedCardIndex]?.images && (
													<ImageSlider
														images={dataBuyItem[selectedCardIndex]?.images}
													/>
												)}
												{!dataBuyItem[selectedCardIndex]?.images && (
													<img
														src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
														alt='Фото товара'
													/>
												)}
											</PopupCabinet>

											<li className='CardLi'>
												<p>Date:</p>
												<p className='date'>
													{new Date(item.createdAt).toLocaleString(
														'tr-TR',
														options
													)}
												</p>
											</li>

											<li className='CardLi'>
												<p>Link:</p>
												<p>
													{' '}
													<a href={item.link}>
														Ссылка на объявление в других источниках
													</a>{' '}
												</p>
											</li>
											<li className='CardLi'>
												<p>Price:</p>
												<p>{item.price}</p>
											</li>

											<li className='description'>
												<p>Description:</p>
												<p>{item.description}</p>
											</li>
											<li className='CardLi'>
												<p>Phone:</p>
												<p>{item.phoneNumber}</p>
											</li>
											<li className='CardLi'>
												<p>id:</p>
												<p>{item._id}</p>
											</li>
											<li className='CardViewsCount'>
												<p>viewsCount:</p>
												<p>{item.viewsCount}</p>
											</li>
										</ul>
									</div>
								)
						)}
				</div>
				<h2>ваши объявления:</h2>
			</div>
			<EditModal
				isOpen={isEditModalOpen}
				onClose={() => setEditModalOpen(false)}
				adDataRes={editableAd}
				// Функция для сохранения изменений
			/>

			<div className='last-requests-wrap'>
				{dataPage
					.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
					.map(
						(item, cardIndex) =>
							item.user.email === trashOn && (
								<div key={item._id} className='lastRequestCardsWrap'>
									<ul className='lastRequestCard'>
										{trashOn ? (
											<button
												onClick={() => Trash2(item)}
												className='cartTrash'
											>
												<FontAwesomeIcon icon={faTrash} />
											</button>
										) : null}
										{trashOn ? (
											<button
												onClick={() => editAd(item)}
												className='editButton cartTrash'
											>
												<FontAwesomeIcon icon={faEdit} />
											</button>
										) : null}

										<p>
											Район: {item.district} / Комнат: {item.rooms} / Цена:{' '}
											{item.price}
										</p>
										<li className='imgLi'>
											{item.images ? (
												item.images.map((item, index) => (
													<img
														key={index}
														onClick={() => photo(cardIndex)}
														src={`https://evbulsun.site:9000/uploads/${item}`}
														alt='Фото товара'
													/>
												))
											) : (
												<img
													src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
													alt='Фото товара'
												/>
											)}
										</li>

										<PopupCards isOpen={isOpen} onClose={handleClose}>
											{dataPage[selectedCardIndex]?.images && (
												<ImageSlider
													images={dataPage[selectedCardIndex]?.images}
												/>
											)}
											{!dataPage[selectedCardIndex]?.images && (
												<img
													src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
													alt='Фото товара'
												/>
											)}
										</PopupCards>

										<li className='CardLi'>
											<p>Date:</p>
											<p className='date'>
												{new Date(item.createdAt).toLocaleString(
													'tr-TR',
													options
												)}
											</p>
										</li>
										<li className='CardLi'>
											<p>Посуточно или короткий срок:</p>
											<p>{item.howlong}</p>
										</li>
										<li className='CardLi'>
											<p>Город:</p>
											<p>{item.city}</p>
										</li>
										<li className='CardLi'>
											<p>Количество комнат:</p>
											<p>{item.rooms}</p>
										</li>

										<li className='CardLi'>
											<p>Тип:</p>
											<p>{item.type}</p>
										</li>
										<li className='CardLi'>
											<p>Район:</p>
											<p>{item.district}</p>
										</li>
										<li className='description'>
											<p>Дополнительная информация:</p>
											<ReactMarkdown>{item.additionalInfo}</ReactMarkdown>
										</li>
										<li className='CardLi'>
											<p>Тип недвижимости:</p>
											<p>{item.propertyType}</p>
										</li>

										<li className='CardLi'>
											<p>Тип кухни:</p>
											<p>{item.kitchenType}</p>
										</li>
										<li className='CardLi'>
											<p>Общее количество этажей:</p>
											<p>{item.totalFloors}</p>
										</li>
										<li className='CardLi'>
											<p>Текущий этаж:</p>
											<p>{item.currentFloor}</p>
										</li>
										<li className='CardLi'>
											<p>Мебель:</p>
											<p>{item.furniture}</p>
										</li>
										<li className='CardLi'>
											<p>Цена:</p>
											<p>{item.price}</p>
										</li>
										<li className='CardLi'>
											<p>Валюта:</p>
											<p>{item.currency}</p>
										</li>
										<li className='CardLi'>
											<p>Адрес:</p>
											<p>
												<a
													href={item.address}
													target='_blank'
													rel='noopener noreferrer'
												>
													{item.address}
												</a>
											</p>
										</li>
										<li className='CardLi'>
											<p>Площадь:</p>
											<p>{item.area}</p>
										</li>
										<li className='CardLi'>
											<p>Телефон:</p>
											<a href={`tel:${item.phone}`}>{item.phone}</a>
										</li>

										<li className='CardLi'>
											<p>Ссылка:</p>
											{!isToastVisible && (
												<a
													href={`https://evbulsun.site:9000${
														'howlong' in item
															? '/seller-datapage-rental-item/'
															: '/seller-datapage-item/'
													}${item._id}`}
													onClick={e => {
														e.preventDefault()
														handleCopy(
															'howlong' in item
																? `/seller-datapage-rental-item/${item._id}`
																: `/seller-datapage-item/${item._id}`
														)
													}}
												>
													https://evbulsun.site
													{'howlong' in item
														? '/seller-datapage-rental-item/'
														: '/seller-datapage-item/'}
													${item._id}
												</a>
											)}
											{isToastVisible && (
												<div className='toast'>
													<h4>Ссылка скопирована в буфер обмена!</h4>
												</div>
											)}
										</li>
									</ul>
								</div>
							)
					)}
			</div>
		</>
	)
}

export default Cabinet
