import React from 'react'
import { Link } from 'react-router-dom'
import Popup from '../Popup/homePopup'
import style from './RegistrationsForm.module.sass'
const RegistrationForm = ({
	isOpen,
	handleClose,
	handleSubmit,
	handleNameChange,
	handleEmailChange,
	handlePasswordChange,
	handleConfirmPasswordChange,
	formErrors,
	name,
	email,
	password,
	confirmPassword,
}) => (
	<div>
		<Popup isOpen={isOpen} onClose={handleClose}>
			<h2>Вы зарегистрировались 👍</h2>
			<h3>Пожалуйста проверьте почту и актевируйте аккаунт.</h3>

			<Link className='btn' to='/login'>
				<p>Войти</p>
			</Link>
		</Popup>

		<fieldset onSubmit={handleSubmit}>
			<label>
				Имя:
				<input type='text' value={name} onChange={handleNameChange} />
				{formErrors.name && <span className='error'>{formErrors.name}</span>}
			</label>
			<label>
				Email:
				<input type='email' value={email} onChange={handleEmailChange} />
				{formErrors.email && <span className='error'>{formErrors.email}</span>}
			</label>
			<label>
				Пароль:
				<input
					type='password'
					value={password}
					onChange={handlePasswordChange}
				/>
				{formErrors.password && (
					<span className='error'>{formErrors.password}</span>
				)}
			</label>
			<label>
				Повторите пароль:
				<input
					type='password'
					value={confirmPassword}
					onChange={handleConfirmPasswordChange}
				/>
				{formErrors.confirmPassword && (
					<span className='error'>{formErrors.confirmPassword}</span>
				)}
			</label>
		</fieldset>
		<p className={style.privacy}>
			Регистрируясь вы соглашаетесь с политикой конфиденциальности.
		</p>
	</div>
)

export default RegistrationForm
