import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import imgHome02 from '../../img/home02.jpeg'
import imgHome03 from '../../img/home03.jpeg'
import imgHome04 from '../../img/home04.jpeg'
import imgHome01 from '../../img/home1.jpeg'
import Video from '../HowToUseVideo'
import Popup from '../Popup/homePopup'
import style from './Home.module.sass'

const cards = [
	{
		id: 1,
		title: '1 регистрация',
		imageUrl: imgHome01,
		description:
			'Для регистрации укажите email, получите письмо и перейдите по ссылке для активации!',
	},
	{
		id: 2,
		title: '2 описание',
		imageUrl: imgHome02,
		description:
			'Укажите все предпочтения, например, бассейн обязателен или чем дешевле, тем лучше.',
	},
	{
		id: 3,
		title: '3 выбрать лучшее',
		imageUrl: imgHome03,
		description:
			'Дождитесь предложений от хозяев жилья и выбирайте среди тех, кто сам ищет Вас.',
	},
	{
		id: 4,
		title: '4 жить',
		imageUrl: imgHome04,
		description:
			'Сервис рассчитан на русскоязычных и здесь может начаться ваша легкая интеграция в новый город.',
	},
]

function Home() {
	const [isOpen, setIsOpen] = useState(false)
	const [homeTextActive, setHomeTextActive] = useState(false)

	const handleOpen = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<div>
			<Helmet>
				<title>Поиск жилья на ваших условиях.</title>
				<meta
					name='description'
					content='Наш сайт предоставляет новый подход к поиску недвижимости. Заполните форму на нашем сайте и получите лучшие предложения от хозяев жилья, которые подписаны для рассылки новых запросов. Это экономит ваше время и дает возможность выбрать подходящее жилье без лишних хлопот. Начните поиск недвижимости уже сегодня!'
				/>
			</Helmet>
			<Popup isOpen={isOpen} onClose={handleClose}>
				<h2>Пожалуйста выберите</h2>
				<Link className='btn' to='/rent-form'>
					<p>Хочу найти аренду</p>
				</Link>
				<Link className='btn' to='/buy-form'>
					<p>Хочу купить квартиру</p>
				</Link>
			</Popup>
			<div className={style.home}>
				<h1 className={style.homeTitle1}>
					Найти в аренду или купить недвижимость
				</h1>
				<h2 className={style.homeTitle2}>
					Тут можно дать объявление о желании арендовать или купить квартиру.
				</h2>
				<p className={style.homeText1}>
					Устали от поиска квартиры на группах, каналах и системах бронирования?
				</p>
				<div
					className={style.homeText1Btn}
					onClick={() => setHomeTextActive(!homeTextActive)}
				>
					подробнее
				</div>
				<div className={style.homeTextWrap}>
					<p
						className={
							!homeTextActive ? style.homeText2 : style.homeText2Active
						}
					>
						Без звонков, без лишних хлопот.
					</p>
					<p
						className={
							!homeTextActive ? style.homeText3 : style.homeText3Active
						}
					>
						{' '}
						Наш сайт предоставляет новый подход к поиску недвижимости. Вместо
						того, чтобы обзванивать все объявления в городе, вы можете просто
						заполнить форму на нашем сайте, указав ваши предпочтения. Хозяева
						недвижимости и риелторы будут присылать Вам свои предложения. Вам не
						смогут звонить пока вы не ответите. Предложения будут поступать на
						указанный вами электронный адрес и в личный кабинет. Это экономит
						ваше время и дает возможность выбрать подходящее жилье без лишних
						хлопот.
					</p>
					{!homeTextActive ? null : <Video />}
				</div>
				<h3 className={style.homeCheckBtnTitle1}>
					Не рискуйте с покупкой квартиры, не зная ее реальное состояние.
				</h3>
				<h3 className={style.homeCheckBtnTitle2}>
					Обезопасьте свою покупку или аренду недвижимости. Проверка квартиры от
					EvBulsun.
				</h3>
				<Link className='link-text-decor-none' to='/check-house'>
					<div className={style.homeText1Btn}>подробнее</div>
				</Link>
				<button
					onClick={handleOpen}
					className={`${style.homeBeginBtn} ${'homeBeginBtn'} ${
						style.homeBeginBtnUp
					}`}
				>
					найти квартиру
				</button>
				<div className={style.homeImgWrapp}>
					{cards.map(card => (
						<div key={card.id}>
							<img src={card.imageUrl} alt={card.title} />
							<h2>{card.title}</h2>
							<p>{card.description}</p>
						</div>
					))}
				</div>
				<button
					onClick={handleOpen}
					className={`${style.homeBeginBtn} ${'homeBeginBtn'}`}
				>
					найти квартиру
				</button>
			</div>
		</div>
	)
}
export default Home
