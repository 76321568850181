
import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import { useSelector } from "react-redux";
import { selectIsAuth } from '../../Redux/LoginSlice';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../RentalOwner/seller.sass';
import PopupCards from '../PopupCards';
import ImageSlider from '../SliderPreview';
import { Link } from 'react-router-dom';


function LastRequests() {

    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [trashOn, setTrashOn] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);

    const isAuth = useSelector(selectIsAuth);
    useEffect(() => {
        if (isAuth) {
            const { email } = isAuth;
            if (email === "tehnoshark2284@gmail.com") {
                setTrashOn(true);
            }
        }
    }, [isAuth]);


    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get('/buyitem');

            setData(result.data);
        };
        fetchData();
    }, []);

    const searchFilter = (searchQuery, item) => {
        return Object.values(item).some(value => {
            return (typeof value === 'string' || value instanceof String) && value.toLowerCase().includes(searchQuery.toLowerCase().trim())
        });
    }

    useEffect(() => {
        const filtered = data.filter(item => searchFilter(searchQuery, item));
        setFilteredData(filtered);

    }, [searchQuery, data]);

    const photo = (cardIndex) => {
        setSelectedCardIndex(cardIndex);
        setIsOpen(true)
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    const Trash = async (item) => {
        await axios.delete(`/buyItem/${item}`);

    };
    const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZone: "Europe/Istanbul",
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };

    return (
        <div className='last-requests'>
            <input type="text" placeholder="Search" onChange={e => setSearchQuery((e.target.value).toString())} />
            <h2>Тут все ценовые запросы, которые делали люди до вас.</h2>
            <p>Воспльзуйтесь поиском что бы найти похожее.</p>
            <div className="last-requests-wrap">
                {filteredData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, cardIndex) => (
                    <div key={item._id} className="lastRequestCardsWrap">
                        <ul className="lastRequestCard">
                            {trashOn ? <button onClick={() => Trash(item._id)} className='cartTrash'><FontAwesomeIcon icon={faTrash} /></button> : null}
                            <li className='imgLi'>
                                {item.images ? item.images.map((item, index) => (
                                    <img
                                        key={index}

                                        onClick={() => photo(cardIndex)}
                                        src={`https://evbulsun.site:9000/uploads/${item}`}
                                        alt="Фото товара"
                                    />
                                )) : <img src="https://evbulsun.site:9000/uploads/hqdefault.jpg" alt="Фото товара" />}
                            </li>

                            <PopupCards isOpen={isOpen} onClose={handleClose}>
                                {filteredData[selectedCardIndex]?.images && <ImageSlider images={filteredData[selectedCardIndex]?.images} />}
                                {!filteredData[selectedCardIndex]?.images && <img src="https://evbulsun.site:9000/uploads/hqdefault.jpg" alt="Фото товара" />}
                            </PopupCards>


                            <li className='CardLi'>
                                <p>Date:</p>
                                <p className='date'>{new Date(item.createdAt).toLocaleString("tr-TR", options)}</p>
                            </li>

                            <li className='CardLi'>
                                <p>Link:</p>
                                <p> <a href={item.link}>Ссылка на объявление в других источниках</a> </p>
                            </li>
                            <li className='CardLi'>
                                <p>Price:</p>
                                <p>{item.price}</p>
                            </li>

                            <li className='description' >
                                <p>Description:</p>
                                <p>{item.description}</p>
                            </li>
                            <li className='CardLi'>
                                <p>Phone:</p>
                                <p>{item.phoneNumber}</p>
                            </li>
                            <li className='CardLi'>
                                <p>id:</p>
                                <p>{item._id}</p>
                            </li>
                            <li className='CardViewsCount'>
                                <p>viewsCount:</p>
                                <p>{item.viewsCount}</p>
                            </li>
                            <Link className="link-text-decor-none" to={`/seller-owner-item/${item._id}`}>
                                <button>Подробно</button>
                            </Link>

                        </ul>
                    </div>
                ))}
            </div>
        </div >
    );
}

export default LastRequests;