import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from '../../axios';
import { selectIsAuth } from '../../Redux/LoginSlice';
import Popup from '../Popup/homePopup';
import Popup2 from '../Popup2/Popap';
import style from '../BuyForm/BuyForm.module.sass';

function Reviews() {
    const [overallScore, setOverallScore] = useState('');
    const [city, setCity] = useState('');
    const [agencyNname, setAgencyNname] = useState('');
    const [impression, setImpression] = useState('');
    const [isPawned, setIsPawned] = useState('');
    const [description, setDescription] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [activated, setActivated] = useState('');
    const formRef = useRef(null);

    const handleСityCountChange = (e) => {
        setCity(e.target.value);
    };
    const handleAgencyNnameChange = (e) => {
        setAgencyNname(e.target.value);
    };
    const handleImpressionhange = (e) => {
        setImpression(e.target.value);
    };

    const handleOverallScoreChange = (e) => {
        setOverallScore(e.target.value);
    };

    const handlePawnedChange = (e) => {
        setIsPawned(e.target.value);
    };


    const [error, setError] = useState(false);
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        if (e.target.value.length > 300) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            city: city,
            overallScore: overallScore,
            agencyNname: agencyNname,
            isPawned: isPawned,
            description: description,
            impression: impression
        };

        try {
            const { data } = await axios.post('/reviews', formData);
            console.log(data._id);
            formRef.current.reset();
            setIsOpen(true);

        } catch (error) {
            console.warn(error);
            alert('Oшибка заполнения формы!!! Что бы перезагрузить страницу нажмите OK');
        }


    };



    const handleClose = () => {
        setIsOpen(false);
    };


    const isAuth = useSelector(selectIsAuth);
    useEffect(() => {
        if (isAuth) {
            const { isActivated } = isAuth;
            setActivated(isActivated);
        }
    }, [isAuth]);


    return (
        <div className={`${style.BuyForm} ${"form"}`}>

            {activated ? null : <Popup2 />}
            <Popup isOpen={isOpen} onClose={handleClose}>
                <h2>Отзыв отправлен. 👍</h2>
                <Link className='btn' to='/all-reviews'><p onClick={() => setIsOpen(false)} >Назад</p></Link>
            </Popup>

            <form onSubmit={handleSubmit} ref={formRef}>
                <label htmlFor="agencyName">
                    <p>Название агентства и фирмы:</p>
                    <input
                        type="text"
                        id="agencyName"
                        name="agencyName"
                        value={agencyNname}
                        onChange={handleAgencyNnameChange}
                        required
                    />
                </label>
                <label htmlFor="city">
                    <p>Выберите город:</p>
                    <select
                        id="city"
                        name="city"
                        value={city}
                        onChange={handleСityCountChange}
                        required
                    >
                        <option value="">Какой город?</option>
                        <option value="Анталия">Анталия</option>
                        <option value="Алания">Алания</option>
                        <option value="Бодрум">Бодрум</option>
                        <option value="Измир">Измир</option>
                        <option value="Сиде">Сиде</option>
                        <option value="Мерсин">Мерсин</option>
                        <option value="Манавгат">Манавгат</option>
                    </select>
                </label>
                <label htmlFor="OverallScore">
                    <p>Общая оценка:</p>
                    <select
                        id="OverallScore"
                        name="OverallScore"
                        value={overallScore}
                        onChange={handleOverallScoreChange}
                        required
                    >
                        <option value="">Общая оценка?</option>
                        <option value="1 - Ужасно">1 - Ужасно</option>
                        <option value="2 - Плохо">2 - Плохо</option>
                        <option value="3 - Средне">3 - Средне</option>
                        <option value="4 - Хорошо">4 - Хорошо</option>
                        <option value="5 - Отлично">5 - Отлично</option>
                    </select>
                </label>
                <div>
                    <span>Рекомендовали бы друзьям?</span>
                    <label htmlFor="pawnedYes">
                        <p>Да</p>
                        <input
                            type="radio"
                            id="pawnedYes"
                            name="isPawned"
                            value="Да"
                            checked={isPawned === 'Да'}
                            onChange={handlePawnedChange}
                            required
                        />
                    </label>
                    <label htmlFor="pawnedNo">
                        <p>Нет</p>
                        <input
                            type="radio"
                            id="pawnedNo"
                            name="isPawned"
                            value="Нет"
                            checked={isPawned === 'Нет'}
                            onChange={handlePawnedChange}
                            required
                        />
                    </label>
                </div>
                <label htmlFor="impression">
                    <p>Впечатление в двух словах:</p>
                    <input
                        type="text"
                        id="impression"
                        name="impression"
                        value={impression}
                        onChange={handleImpressionhange}
                        required
                    />
                </label>
                <label htmlFor="description">
                    <p>Текст отзыва:</p>
                    <textarea
                        id="description"
                        name="description"
                        value={description}
                        onChange={handleDescriptionChange}
                        placeholder='Здесь нужно указать все ваши впечатление!!! Ограничение 300 символов.'
                        className={error ? `${style.error}` : ""}
                        required
                        maxLength={300}
                    />
                    <p className={error ? `${style.block}` : `${style.none}`}>Привышен лимит в 300 символов!</p>
                </label>
                <button disabled={description.length > 300} className='btn' type="submit">Отправить</button>
            </form>
        </div>
    );
}

export default Reviews;
