
import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import { useSelector } from "react-redux";
import { selectIsAuth } from '../../Redux/LoginSlice';
import style from '../Request/Request.module.sass';
import { Helmet } from 'react-helmet';

import '../RentalOwner/seller.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const groupBy = (array, key) => {
    return array.reduce((result, currentItem) => {
        (result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem);
        return result;
    }, {});
};

const getAverageScore = (array) => {
    const sum = array.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.overallScore.split(" - ")[0]);
    }, 0);
    return (sum / array.length).toFixed(1);
};


function AllReviews() {

    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [trashOn, setTrashOn] = useState('');
    const [isPawned, setIsPawned] = useState('');
    const [city, setCity] = useState('');
    const [overallScore, setOverallScore] = useState('');
    const [itemDeleted, setItemDeleted] = useState(null);
    const [averageScores, setAverageScores] = useState([]);

    useEffect(() => {
        const filtered = data.filter((item) => searchFilter(searchQuery, item));
        setFilteredData(filtered);
        const groupedData = groupBy(filtered, "agencyNname");
        const averageScoresArray = Object.entries(groupedData).map(
            ([key, value]) => {
                return {
                    agencyNname: key,
                    averageScore: getAverageScore(value),
                };
            }
        );
        setAverageScores(averageScoresArray);
    }, [searchQuery, data]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get('/reviews');
            setData(result.data);
        };
        fetchData();
    }, []);

    const isAuth = useSelector(selectIsAuth);
    useEffect(() => {
        if (isAuth) {
            const { email } = isAuth;
            if (email) {
                setTrashOn(email);
            }
        }
    }, [isAuth]);

    const searchFilter = (searchQuery, item) => {
        return Object.values(item).some(value => {
            return (typeof value === 'string' || value instanceof String) && value.toLowerCase().includes(searchQuery.toLowerCase().trim())
        });
    }

    useEffect(() => {
        const filtered = data.filter(item => searchFilter(searchQuery, item));
        setFilteredData(filtered);

    }, [searchQuery, data]);

    const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZone: "Europe/Istanbul",
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleOverallScoreChange = (event) => {
        setOverallScore(event.target.value);
    };

    const handlePawnedChange = (event) => {
        setIsPawned(event.target.value);
    };



    const filterData = (data) => {
        let filteredData = data;

        if (city) {
            filteredData = filteredData.filter((item) => item.city === city);
        }

        if (overallScore) {
            filteredData = filteredData.filter((item) => item.overallScore === overallScore);
        }

        if (isPawned) {
            filteredData = filteredData.filter((item) => item.isPawned === isPawned);
        }

        return filteredData;
    };

    const Trash = async (id, index) => {
        await axios.delete(`/reviews/${id}`);
        setItemDeleted(index);
        const result = await axios.get('/reviews');
        setData(result.data);
    };

    return (
        <>
            <Helmet>
                <title>Отзывы о агентствах недвижимости</title>
                <meta name="description" content="Отзывы о лучших агентствах недвижимости в городе. Читайте отзывы от реальных людей и выбирайте лучшего агента для покупки, продажи или аренды недвижимости. Сравнивайте цены и услуги, оставляйте свой отзыв и помогайте другим принять правильное решение." />
            </Helmet>

            <h1 className='lastRequestsTitle'>Отзывы клиентов о других агентствах недвижимости - полезная информация для выбора надежного партнера.</h1>
            <div className='last-requests allReviews'>
                <input type="text" placeholder="Search" onChange={e => setSearchQuery((e.target.value).toString())} />
                <h2>Тут все отзывы, которые делали люди до вас.</h2>
                <p>Воспльзуйтесь поиском что бы найти агентство.</p>
            </div >

            <div className={style.homeBeginBtn}><Link to='/reviews'><button className='homeBeginBtn'>Оставить отзыв</button></Link></div>

            <div className={style.request}>
                <div className={style.filterWrapp}>
                    <div>
                        <label htmlFor="city">Город:</label>
                        <select id="city" name="city" value={city} onChange={handleCityChange}>
                            <option value="">Город?</option>
                            <option value="Анталия">Анталия</option>
                            <option value="Алания">Алания</option>
                            <option value="Бодрум">Бодрум</option>
                            <option value="Измир">Измир</option>
                            <option value="Сиде">Сиде</option>
                            <option value="Мерсин">Мерсин</option>
                            <option value="Манавгат">Манавгат</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="isPawned">Рекомендуют:</label>
                        <select id="isPawned" name="isPawned" value={isPawned} onChange={handlePawnedChange}>
                            <option value="">All</option>
                            <option value="Да">Да</option>
                            <option value="Нет">Нет</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="overallScore">Оценка:</label>
                        <select
                            id="overallScore"
                            name="overallScore"
                            value={overallScore}
                            onChange={handleOverallScoreChange}
                        >
                            <option value="">Общая оценка?</option>
                            <option value="1 - Ужасно">1 - Ужасно</option>
                            <option value="2 - Плохо">2 - Плохо</option>
                            <option value="3 - Средне">3 - Средне</option>
                            <option value="4 - Хорошо">4 - Хорошо</option>
                            <option value="5 - Отлично">5 - Отлично</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={`${style.requestCarts} ${style.reviewsCarts}`}>
                <div className={`${style.cartHeader} ${style.cartHeaderReviews}`}>
                    <div className={style.cartHeaderDescr}>Описание</div>
                    <div className={style.cartHeaderIsPawned}>Рекомендуют:</div>
                    <div className={style.cartHeaderCity}>Город</div>
                    <div className={`${style.cartHeaderStar} ${style.reviewsStar}`}>*</div>
                </div>

                {filterData(filteredData).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => (
                    <div key={index} className={style.cartWrapp}>
                        {trashOn === item.user.email && (<button onClick={() => Trash(item._id, index)} className={`${style.cartTrash} ${itemDeleted === index ? style.active : ''}`}><FontAwesomeIcon icon={faTrash} /></button>)}

                        <div className={`${style.cart} ${style.reviewsCart}`}>
                            <div className={style.cartDescrAllReviews}>
                                <div>
                                    <p>Имя агентства или фирмы:</p>
                                    <span>"{item.agencyNname}"</span>
                                    {averageScores.map((score, index) => {
                                        if (score.agencyNname === item.agencyNname) {
                                            return (
                                                <p key={index} className={style.averageScore}>Средний балл: {score.averageScore}</p>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                                <p className={style.impression}>{item.impression}</p>
                                <p className={style.AllReviewsDescription}>{item.description}</p>
                                <p className={style.articleTime}>{new Date(item.createdAt).toLocaleString("tr-TR", options)}</p>
                            </div>
                            <div className={style.cartIsPawned}>{item.isPawned}</div>
                            <div className={style.cartCity}>{item.city}</div>
                            {item.overallScore === '1 - Ужасно' && (
                                <div className={`${style.star} ${style.veryBadScore}`}>
                                    {item.overallScore}
                                </div>
                            )}
                            {item.overallScore === '2 - Плохо' && (
                                <div className={`${style.star} ${style.BedScore}`}>
                                    {item.overallScore}
                                </div>
                            )}
                            {item.overallScore === '3 - Средне' && (
                                <div className={`${style.star} ${style.mediumScore}`}>
                                    {item.overallScore}
                                </div>
                            )}
                            {item.overallScore === '4 - Хорошо' && (
                                <div className={`${style.star} ${style.goodScore}`}>
                                    {item.overallScore}
                                </div>
                            )}
                            {item.overallScore === '5 - Отлично' && (
                                <div className={`${style.star} ${style.veryGoodScore}`}>
                                    {item.overallScore}
                                </div>
                            )}
                        </div>
                        <div className={style.cartDescrMedia}>
                            <div className={style.cartDescrAllReviews}>
                                <div>
                                    <p>Имя агентства или фирмы:</p>
                                    <span>"{item.agencyNname}"</span>
                                    {averageScores.map((score, index) => {
                                        if (score.agencyNname === item.agencyNname) {
                                            return (
                                                <p key={index} className={style.averageScore}>Средний балл: {score.averageScore}</p>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                                <p className={style.impression}>{item.impression}</p>
                                <p className={style.AllReviewsDescription}>{item.description}</p>
                                <p className={style.articleTime}>{new Date(item.createdAt).toLocaleString("tr-TR", options)}</p>
                            </div>

                        </div>
                    </div>

                ))}

            </div>
        </>

    );
}

export default AllReviews;