
import React from 'react';
import telegramBlack from '../../img/telegram.svg';
import './TelegramWidget.sass';
function TelegramWidget() {
    return (
        <div>
            <div className="telegram">
                <div className="telegram-white-border">
                    <div className="telegram-wrap">
                        <a href="https://t.me/kuplu_dom_evbulsun">
                            <div className="t-item-wrap">
                                <img className="t-logo" src={telegramBlack} alt="telegram logo" />
                                <div className="t-text-wrap">
                                    <h3 className="t-title">Покупка квартир</h3>
                                    <p className="t-text">Сюда поступают все запросы на ПОКУПКУ</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="telegram-wrap">
                        <a href="https://t.me/evbulsunArendaAntalya">
                            <div className="t-item-wrap">
                                <img className="t-logo" src={telegramBlack} alt="telegram logo" />
                                <div className="t-text-wrap">
                                    <h3 className="t-title">Аренда квартир</h3>
                                    <p className="t-text">В этом канале все запросы по аренде</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TelegramWidget