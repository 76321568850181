import React, { useState } from 'react'
import axios from '../../axios'
import Loader from '../Loader'
import style from './BuyItem.module.sass'

function ArticlePost() {
	const [loading, setLoading] = useState(false)

	const [formData, setFormData] = useState({
		title: '',
		description: '',
		image: null,
	})

	const handleChange = event => {
		const { name, value } = event.target
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}))
	}

	const handleImageChange = async event => {
		try {
			setLoading(true)
			const { files } = event.target
			if (files && files.length > 0) {
				const imgData = new FormData()
				for (let i = 0; i < files.length; i++) {
					imgData.append('image', files[i])
				}
				const res = await axios.post('/upload', imgData)
				console.log(res)
				// Обновляем состояние formData.image с ответом сервера
				setFormData(prevData => ({
					...prevData,
					image: res.data.url, // или res.data, в зависимости от структуры ответа сервера
				}))
			}
		} catch (error) {
			console.warn(error)
			alert('Ошибка! Возможно, файл слишком большой.')
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async event => {
		event.preventDefault()

		await axios
			.post(`/article`, formData)
			.then(res => {
				alert('Ваше предложение успешно отправленно')
			})
			.catch(err => {
				console.log(err)
				alert('Error submitting data')
			})
	}

	return (
		<>
			<div className={`${style.apartmentForm} ${'form'}`}>
				<form onSubmit={handleSubmit}>
					<label>
						<p>Заголовок</p>
						<input
							type='text'
							name='title'
							value={formData.title}
							onChange={handleChange}
							required
						/>
					</label>
					<br />

					<label>
						<p>Статья:</p>
						<textarea
							name='description'
							value={formData.articl}
							onChange={handleChange}
							required
						/>
					</label>
					<br />

					<label>
						<p>Фотография:</p>
						{loading ? <Loader /> : null}
						<input
							type='file'
							name='images'
							onChange={handleImageChange}
							multiple
						/>
					</label>
					<br />
					<button className='btn' type='submit'>
						Submit
					</button>
				</form>
			</div>
		</>
	)
}

export default ArticlePost
