import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import '../CheckHouse/CheckHouse.sass';
import './Token.sass';
import okImg from '../../img/ok.png';
import falseImg from '../../img/cross.png';


const Token = () => {
    const [tenantAdId, setTenantAdId] = useState('');
    const [chosenAdId, setChosenAdId] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [openAccordion, setOpenAccordion] = useState(null);

    const handleAccordionClick = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get('/token-id');
            setData3(result.data);
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        const formData = {
            tenantAdId: tenantAdId,
            chosenAdId: chosenAdId,
            walletAddress: walletAddress,
        };
        try {
            const { data } = await axios.post('/token-id', formData);
            console.log(data._id);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            const [rentData, buyerItemData] = await Promise.all([
                axios.get('/rent'),
                axios.get('/buyer-item'),

            ]);
            const [buyItemData, rentalOwnerData] = await Promise.all([

                axios.get('/buyitem'),
                axios.get('/rental-owner')
            ]);

            const combinedData1 = rentData.data.concat(buyerItemData.data);
            setData1(combinedData1);

            const combinedData2 = buyItemData.data.concat(rentalOwnerData.data);
            setData2(combinedData2);
        };

        fetchData();
    }, []);

    const tenantIdMatch = data1.some((item) => item._id === tenantAdId);
    const chosenIdMatch = data2.some((item) => item._id === chosenAdId);
    const usedId = data3.some((item) => item.chosenAdId === chosenAdId || item.tenantAdId === tenantAdId);



    return (
        <div className='CheckHouse token'>
            <div className="project-in-development">
                <h3>
                    Проект находится в разработке. Подписывайтесь на канал в телеграмм. Туда придет уведомление о старте.
                </h3>
                <a href="https://t.me/evbcCoin">evbcCoin</a>
            </div>
            <h1>Получите 3333 токена EvBulsunCoin в подарок</h1>
            <p>Дайте объявление о аренде или покупке квартиры на нашем сайте и получите бонус!</p>

            <ul className='CheckHouse-descr token-acc-wrap'>
                <li className='CheckHouse-descr-li' key={0}>
                    <button onClick={() => handleAccordionClick(0)}>Зачем мне EvBulsunCoin?</button>

                    <ul>
                        <li className={`CheckHouse-descr-answer ${openAccordion === 0 ? 'CheckHouse-descr-answer-active' : ''}`}>Сегодня 3333 EVBC это около 5$ но мы планируем развиваться и каждая новая сделка будет увеличивать стоимость токена и доверие инвесторов. Есть много хороших проектов, рост которых превысил несколько тысяч процентов. Выполните не сложные действия и сохраните полученные токены. Завтра они бубут дороже. Если будет на то воля Бога.</li>
                    </ul>

                </li>
                <li className='CheckHouse-descr-li' key={1}>
                    <button onClick={() => handleAccordionClick(1)}>Как будет развиваться проект?</button>

                    <ul>
                        <li className={`CheckHouse-descr-answer ${openAccordion === 1 ? 'CheckHouse-descr-answer-active' : ''}`}>
                            <p>
                                Сегодня мы представляем небольшой стартап с целью предложить инновационный способ поиска жилья для аренды или покупки, который изначально охватывает лишь небольшую часть Анатолийского побережья. Привлекая больше клиентов и вкладывая наши усилия и финансы, мы планируем охватить всю Турцию, выйти на турецкий рынок и охватить другие регионы.
                            </p>

                            <p>
                                Одним из наших стратегических инструментов для продвижения является выпуск токена EvBulsunCoin (EVBC). Этот токен будет распределен среди пользователей, пока количество токенов в их кошельках не достигнет 70%. Мы планируем внедрить токен в проект, сделав его основным средством оплаты за все внутренние услуги на сайте, такие как размещение объявлений или подписка на рассылку о новых объявлениях.
                            </p>

                            <p>
                                Многие знают, что присоединяясь к любому честному проекту в самом начале, можно получить хорошую прибыль. Но мы стремимся не просто вызвать хайп, а наоборот, заинтересованы в планомерном и долгосрочном развитии. В настоящий момент, 3333 токена EVBC эквивалентны примерно 5 долларам, но чем больше людей присоединится к проекту, тем выше будет курс. Если к проекту присоединятся 1000 человек, это уже будет не 5 долларов, а, возможно, значительно больше.
                            </p>

                            <p>
                                Сохраняя свой бонус на непродолжительный срок, вы имеете возможность получить значительную прибыль. Мы планируем распределить 70% всех токенов из общего количества в 1 миллиард. Инвесторы ценят децентрализованные валюты, и если мы проявим терпение, мы все сможем получить впечатляющую отдачу от наших вложений.
                            </p>
                        </li>



                    </ul>

                </li>
                <li className='CheckHouse-descr-li' key={2}>
                    <button onClick={() => handleAccordionClick(2)}>Что нужно сделать для получения токенов?</button>

                    <ul>
                        <li className={`CheckHouse-descr-answer ${openAccordion === 2 ? 'CheckHouse-descr-answer-active' : ''}`}>
                            <p>
                                1. Разместите объявление о аренде или покупке квартиры на нашем сайте и получите отклики от арендодателей или продавцов.
                            </p>
                            <p>
                                2. Приобретите токены EvBulsunCoin на сумму, указанную ниже в разделе "Сколько токенов EVBC мне доступно для получения?".
                            </p>
                            <p>
                                3. Поделитесь своим опытом и оставьте отзыв о сотрудничестве с арендодателем, риелтором или агентством недвижимости на вкладке «Отзывы».
                            </p>
                            <p>
                                4. После завершения сделки вернитесь на сайт.
                            </p>
                            <p>
                                5. Введите ID вашего объявления.
                            </p>
                            <p>
                                6. Введите ID объявления, которое вы выбрали среди ответов от арендодателей.
                            </p>
                            <p>
                                7. Укажите адрес своего кошелька для получения токенов. Он должен совпадать с адресом, с которого вы совершали покупку EVBC.
                            </p>
                            <p>
                                8. Нажмите кнопку отправить.
                            </p>
                        </li>
                    </ul>

                </li>
                <li className='CheckHouse-descr-li' key={3}>
                    <button onClick={() => handleAccordionClick(3)}>Сколько токенов EVBC мне доступно для получения?</button>

                    <ul>
                        <li className={`CheckHouse-descr-answer ${openAccordion === 3 ? 'CheckHouse-descr-answer-active' : ''}`}>
                            <p>
                                При покупке EVBC и продаже их не ранее чем через сутки, вы получите:
                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Количество EVBC</th>
                                        <th>Бонусные токены</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>5 000</td>
                                        <td>1 111 EVBC</td>
                                    </tr>
                                    <tr>
                                        <td>10 000</td>
                                        <td>2 222 EVBC</td>
                                    </tr>
                                    <tr>
                                        <td>15 000</td>
                                        <td>3 333 EVBC</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ul>


                </li>
            </ul>

            <a className='token-buy-link' href='https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x1E00F36D35891CDC7579434A174c0769B64cB954'>
                <div>Перейти на биржу PancakeSwap и купить EVBC</div>
            </a>
            <a className='token-buy-link' href='https://poocoin.app/tokens/0x1e00f36d35891cdc7579434a174c0769b64cb954'>
                <div>По смотреть курс EVBC на сайте PooCoin</div>
            </a>

            <div className='token-form-wrapp'>
                <div className="token-form-input">
                    <label htmlFor='tenant-ad-id'>ID вашего объявления:</label>
                    <div className="input-wrapp">
                        <input
                            type='text'
                            id='tenant-ad-id'
                            value={tenantAdId}
                            onChange={(e) => setTenantAdId(e.target.value)}
                            className={tenantIdMatch ? 'matched' : ''}
                            required
                        />
                        {tenantIdMatch && tenantAdId ? (
                            <img className='okImg' src={okImg} alt='ok' />
                        ) : (
                            tenantAdId && <img className='okImg' src={falseImg} alt='false-img' />
                        )}
                    </div>
                    {!tenantIdMatch && tenantAdId ? (
                        <span className='idNone'>Такой ID не найден</span>
                    ) : null}
                </div>


                <div className="token-form-input">
                    <label htmlFor='chosen-ad-id'>ID объявление которое выбрали:</label>
                    <div className="input-wrapp">
                        <input
                            type='text'
                            id='chosen-ad-id'
                            value={chosenAdId}
                            onChange={(e) => setChosenAdId(e.target.value)}
                            className={chosenIdMatch ? 'matched' : ''}
                            required
                        />
                        {chosenIdMatch && chosenAdId ? (
                            <img className='okImg' src={okImg} alt='ok' />
                        ) : (
                            chosenAdId && <img className='okImg' src={falseImg} alt='false-img' />
                        )}
                    </div>
                    {!chosenIdMatch && chosenAdId ? (
                        <span className='idNone'>Такой ID не найден</span>
                    ) : null}
                </div>
                <div className="token-form-input">
                    <label htmlFor='wallet-address'>Адрес кошелька:</label>
                    <div className="input-wrapp">
                        <input
                            type='text'
                            id='wallet-address'
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            required
                        />
                    </div>

                </div>

                {usedId ? <span>Один из этих ID уже был использован</span> : <button disabled={!chosenIdMatch} className='btn' onClick={handleSubmit}>отправить</button>}
            </div>
        </div>
    );
};

export default Token;
