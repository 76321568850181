import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import PopupCards from '../PopupCards'
import ImageSlider from '../SliderPreview'

const ItemCardRental = ({
	item,
	trashOn,
	Trash,
	photo,
	isOpen,
	handleClose,
	selectedCardIndex,
	paginatedData,
	options,
	cardIndex,
}) => {
	const [showDetails, setShowDetails] = useState(false)

	return (
		<div key={item._id} className='lastRequestCardsWrap'>
			<ul className='lastRequestCard lastRequestCardDataPage'>
				{trashOn ? (
					<button onClick={() => Trash(item._id)} className='cartTrash'>
						<FontAwesomeIcon icon={faTrash} />
					</button>
				) : null}
				<p>
					Район: {item.district} / Комнат: {item.rooms} / Цена: {item.price}{' '}
					{item.currency}
				</p>
				<li className='imgLi'>
					{item.images ? (
						item.images.map((item, index) => (
							<img
								key={index}
								onClick={() => photo(cardIndex)}
								src={`https://evbulsun.site:9000/uploads/${item}`}
								alt='Фото товара'
							/>
						))
					) : (
						<img
							src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
							alt='Фото товара'
						/>
					)}
				</li>
				<PopupCards isOpen={isOpen} onClose={handleClose}>
					{paginatedData[selectedCardIndex]?.images && (
						<ImageSlider images={paginatedData[selectedCardIndex]?.images} />
					)}
					{!paginatedData[selectedCardIndex]?.images && (
						<img
							src='https://evbulsun.site:9000/uploads/hqdefault.jpg'
							alt='Фото товара'
						/>
					)}
				</PopupCards>
				<li className='CardLi'>
					<p>Date:</p>
					<p className='date'>
						{new Date(item.createdAt).toLocaleString('tr-TR', options)}
					</p>
				</li>
				<button onClick={() => setShowDetails(!showDetails)}>Подробно</button>
				{showDetails && (
					<div>
						<li className='CardLi'>
							<p>Посуточно или короткий срок:</p>
							<p>{item.howlong}</p>
						</li>
						<li className='CardLi'>
							<p>Город:</p>
							<p>{item.city}</p>
						</li>
						<li className='CardLi'>
							<p>Количество комнат:</p>
							<p>{item.rooms}</p>
						</li>

						<li className='CardLi'>
							<p>Тип:</p>
							<p>{item.type}</p>
						</li>
						<li className='CardLi'>
							<p>Район:</p>
							<p>{item.district}</p>
						</li>
						<li className='description'>
							<p>Дополнительная информация:</p>
							<ReactMarkdown>{item.additionalInfo}</ReactMarkdown>
						</li>
						<li className='CardLi'>
							<p>Тип недвижимости:</p>
							<p>{item.propertyType}</p>
						</li>

						<li className='CardLi'>
							<p>Тип кухни:</p>
							<p>{item.kitchenType}</p>
						</li>
						<li className='CardLi'>
							<p>Общее количество этажей:</p>
							<p>{item.totalFloors}</p>
						</li>
						<li className='CardLi'>
							<p>Текущий этаж:</p>
							<p>{item.currentFloor}</p>
						</li>
						<li className='CardLi'>
							<p>Мебель:</p>
							<p>{item.furniture}</p>
						</li>
						<li className='CardLi'>
							<p>Цена:</p>
							<p>{item.price}</p>
						</li>
						<li className='CardLi'>
							<p>Валюта:</p>
							<p>{item.currency}</p>
						</li>
						<li className='CardLi'>
							<p>Адрес:</p>
							<p>
								<a
									href={item.address}
									target='_blank'
									rel='noopener noreferrer'
								>
									{item.address}
								</a>
							</p>
						</li>

						<li className='CardLi'>
							<p>Площадь:</p>
							<p>{item.area}</p>
						</li>
						<li className='CardLi'>
							<p>Телефон:</p>
							<a href={`tel:${item.phone}`}>{item.phone}</a>
						</li>

						<li className='CardLi'>
							<p>Ссылка:</p>
							<p>{`https://evbulsun.site/seller-datapage-rental-item/${item._id}`}</p>
						</li>
						<li className='CardLi'>
							<p>Просмотры</p>
							<p>{item.viewsCount}</p>
						</li>
					</div>
				)}
			</ul>
		</div>
	)
}

export default ItemCardRental
